import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Table, Form } from 'react-bootstrap'
import Message from '../components/Message'
import ToastMessage from '../components/ToastMessage'
import Loader from '../components/Loader'
import ModalContainer from '../components/ModalContainer'

import { getQxList, createQx, deleteQx } from '../actions/qxActions'
import {
  QX_LIST_RESET,
  QX_DETAILS_RESET,
  QX_CREATE_FAIL,
  QX_CREATE_RESET,
  QX_DELETE_RESET,
  MC_LIST_RESET,
} from '../constants/qxConstants'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'

const QxListScreen = ({ history, match }) => {
  // For toast message
  const [toastMessages, setToastMessages] = useState([])
  const [showCreate, setShowCreate] = useState(false)
  const [nameCreate, setNameCreate] = useState('')
  const [abbrevCreate, setAbbrevCreate] = useState('')

  const toastOnCloseHandler = async (uid) => {
    setToastMessages((toastMessages) =>
      toastMessages.filter((message) => message.uid !== uid)
    )
  }

  const handleOpenCreate = () => {
    setShowCreate(true)
  }

  const handleCreateConfirm = (e) => {
    if (!nameCreate || !abbrevCreate) {
      dispatch({
        type: QX_CREATE_FAIL,
        payload: 'Questionnaire name and code are required',
      })
    } else {
      dispatch(createQx({ chin_name: nameCreate, abbrev: abbrevCreate }))
    }
  }

  const handleCloseCreate = () => {
    setShowCreate(false)
  }

  const goToEditHandler = async (qx) => {
    dispatch({ type: QX_LIST_RESET })
    dispatch({ type: QX_DETAILS_RESET })
    dispatch({ type: MC_LIST_RESET })
    history.push(`/qx/${qx._id}/edit`)
  }

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const qxList = useSelector((state) => state.qxList)
  const {
    qx_list,
    error: errorGetList,
    loading: loadingGetList,
    success: successGetList,
  } = qxList

  const qxCreate = useSelector((state) => state.qxCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    qx: createdQx,
  } = qxCreate

  const qxDelete = useSelector((state) => state.qxDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = qxDelete

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      dispatch({ type: QX_LIST_RESET })
      history.push('/login')
    } else {
      if (!loadingGetList && !successGetList && !errorGetList) {
        dispatch(getQxList(pageNumber))
      }

      if (successCreate) {
        dispatch({ type: QX_CREATE_RESET })
        dispatch({ type: QX_LIST_RESET })
        history.push(`/qx/${createdQx._id}/edit`)
      }

      if (successDelete) {
        dispatch({ type: QX_DELETE_RESET })
        dispatch(getQxList(pageNumber))
      }
    }
  }, [
    dispatch,
    history,
    userInfo,
    pageNumber,
    logout,
    createdQx,
    loadingGetList,
    errorGetList,
    errorDelete,
    successGetList,
    successCreate,
    successDelete,
  ])

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  const deleteQxHandler = async (qx) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteQx(qx._id))
    }
  }

  return (
    <>
      {/* Display toast messages */}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          x: '0',
          y: '0',
          height: '80vh',
          width: '80vw',
          zIndex: 1,
        }}
      >
        {toastMessages.map((toastMessage) => (
          <ToastMessage
            key={toastMessage.uid}
            variant={toastMessage.variant}
            onClose={() => toastOnCloseHandler(toastMessage.uid)}
          >
            {toastMessage.message}
          </ToastMessage>
        ))}
      </div>

      <Row className='align-items-center'>
        <Col>
          <h1 style={headingStyle}>Questionnaire List</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={handleOpenCreate}>
            <i className='fas fa-plus'></i> Create Questionnaire
          </Button>
        </Col>
      </Row>

      <ModalContainer
        title='Create Questionnaire'
        show={showCreate}
        saveAvailable={true}
        handleSave={handleCreateConfirm}
        handleClose={handleCloseCreate}
      >
        <Form>
          {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

          <Form.Group controlId='name'>
            <Form.Label style={{ whiteSpace: 'normal' }}>
              Questionnaire Name
            </Form.Label>
            <Form.Control
              type='text'
              value={nameCreate}
              onChange={(e) => {
                setNameCreate(e.target.value)
              }}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='status'>
            <Form.Label style={{ whiteSpace: 'normal' }}>
              Questionnaire Code
            </Form.Label>
            <Form.Control
              type='text'
              value={abbrevCreate}
              onChange={(e) => {
                setAbbrevCreate(e.target.value)
              }}
            ></Form.Control>
          </Form.Group>
        </Form>
      </ModalContainer>

      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

      {loadingGetList || loadingCreate || loadingDelete ? (
        <Loader />
      ) : errorGetList ? (
        <Message variant='danger'>{errorGetList}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>NAME</th>
              <th>CODE</th>
              <th>CREATED AT</th>
              <th>LAST EDITED BY</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!logout &&
              qx_list.map((qx) => (
                <tr key={qx._id}>
                  <td className='align-middle'>{qx.chin_name}</td>
                  <td className='align-middle'>{qx.abbrev}</td>
                  <td className='align-middle'>
                    {qx.createdAt.substring(0, 10)}
                  </td>
                  <td className='align-middle'>{qx.user.email}</td>
                  <td className='align-middle'>
                    <Button
                      variant='primary'
                      className='btn-sm mx-1'
                      onClick={() => goToEditHandler(qx)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant='danger'
                      className='btn-sm mx-1'
                      onClick={() => deleteQxHandler(qx)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default QxListScreen
