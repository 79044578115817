import moment from 'moment-timezone'

const transformEmaTaskListForDisplay = (emaTaskList) => {
  let uniqueKey = [] // notification_datetime,timezone,reminder
  let result = {}

  for (let i = 0; i < emaTaskList.length; ++i) {
    let taskDatetime = moment.tz(
      emaTaskList[i].notification_datetime,
      'YYYY-MM-DD HH:mm',
      emaTaskList[i].timezone
    )
    let now = moment().tz(emaTaskList[i].timezone)
    if (taskDatetime >= now) {
      // Filter out the entries in the past
      let key = `${emaTaskList[i].notification_datetime},${emaTaskList[i].timezone},${emaTaskList[i].reminder}`
      if (uniqueKey.includes(key)) {
        // the key has already exists
        result[key].qx_list.push(emaTaskList[i].questionnaire.abbrev)
      } else {
        // the key has not been added yet
        result[key] = {
          notification_datetime: emaTaskList[i].notification_datetime,
          timezone: emaTaskList[i].timezone,
          qx_list: [],
          reminder: emaTaskList[i].reminder,
          key: key,
        }
        result[key].qx_list.push(emaTaskList[i].questionnaire.abbrev)
        uniqueKey.push(key)
      }
    }
  }

  return Object.values(result)
}

export default transformEmaTaskListForDisplay
