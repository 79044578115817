const getResponseItemsByQuestion = (question) => {
  if (question) {
    return question.other_info.map((x, index) => {
      let questionType = question.other_info[index].type
      if (questionType === 'text') {
        return (
          <option key={'dependency_rpos' + index} value={index + 1}>
            Item {index + 1} (Text Box)
          </option>
        )
      }
      if (questionType === 'mc') {
        // mc type
        return (
          <option key={'dependency_rpos' + index} value={index + 1}>
            Item {index + 1} (Multiple Choice)
          </option>
        )
      }
    })
  }
}

export default getResponseItemsByQuestion
