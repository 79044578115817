import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form, Table } from 'react-bootstrap'
import ModalContainer from './ModalContainer'
import Loader from './Loader'
import Message from './Message'
import { labManagerGetUsers } from '../../admin_module/actions/userActions'
import { getStudyAccessUsers, updateStudyAccess } from '../actions/studyActions'
import {
  STUDY_ACCESS_UPDATE_RESET,
  STUDY_ACCESS_USERS_RESET,
} from '../constants/studyConstants'
import { LAB_MANAGER_GET_LABS_USERS_RESET } from '../../admin_module/constants/userConstants'

//import { DOWNLOAD_TEXT_RESP_RESET } from '../constants/participantConstants'

export const AssignAdminAccess = ({ studyId, studyName, onSaveSuccess }) => {
  //It is needed to check if the logged in user is an lab manager
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const labUserList = useSelector((state) => state.labManagerGetLabUsers)
  const {
    labUsers,
    loading: loadingGetLabUsers,
    error: errorGetLabUsers,
    success: successGetLabUsers,
  } = labUserList

  const getStudyAccessUsersState = useSelector(
    (state) => state.studyAccessUsers
  )
  const {
    managedByUsersIds,
    loading: loadingGetStudyAccessUsers,
    error: errorGetStudyAccessUsers,
    success: successGetStudyAccessUsers,
  } = getStudyAccessUsersState

  const updateStudyAccessState = useSelector((state) => state.studyAccessUpdate)
  const {
    error: errorUpdateStudyAccess,
    loading: loadingUpdateStudyAccess,
    success: successUpdateStudyAccess,
  } = updateStudyAccessState

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  const [grantedLabMemberIds, setGrantedLabMemberIds] = useState([])

  useEffect(() => {
    if (successUpdateStudyAccess) {
      onSaveSuccess()
      dispatch({ type: STUDY_ACCESS_UPDATE_RESET })
      dispatch({ type: LAB_MANAGER_GET_LABS_USERS_RESET })
      setShow(false)
    }
    if (successGetStudyAccessUsers) {
      setGrantedLabMemberIds(managedByUsersIds)
      dispatch({ type: STUDY_ACCESS_USERS_RESET })
    }
  }, [
    dispatch,
    successUpdateStudyAccess,
    successGetStudyAccessUsers,
    managedByUsersIds,
    onSaveSuccess,
  ])

  const updateLabMemberSelection = (
    tempGrantedLabMemberIds,
    checked,
    labMemberId
  ) => {
    if (checked) {
      if (!tempGrantedLabMemberIds.find((x) => x === labMemberId)) {
        tempGrantedLabMemberIds.push(labMemberId)
      }
    } else {
      tempGrantedLabMemberIds = tempGrantedLabMemberIds.filter(
        (x) => x !== labMemberId
      )
    }
    return tempGrantedLabMemberIds
  }

  const checkLabMemberHandler = (e, ind, labMembers) => {
    let tempGrantedLabMemberIds = [...grantedLabMemberIds]

    // Support single selection only
    tempGrantedLabMemberIds = updateLabMemberSelection(
      tempGrantedLabMemberIds,
      e.target.checked,
      labMembers[ind]._id
    )

    setGrantedLabMemberIds(tempGrantedLabMemberIds)
  }

  const handleOpen = () => {
    let now = new Date()
    if (
      userInfo &&
      userInfo.expires > now &&
      userInfo.isLabManager &&
      !logout
    ) {
      // Reset the participant selection after reloading the participant list
      setGrantedLabMemberIds([])
      dispatch(labManagerGetUsers())
      dispatch(getStudyAccessUsers(studyId))

      setShow(true)
    } else {
      setShow(false)
    }
  }

  const handleUpdateStudyAccess = async () => {
    dispatch(updateStudyAccess(studyId, grantedLabMemberIds))
  }

  const handleClose = () => {
    if (!loadingUpdateStudyAccess) {
      dispatch({ type: STUDY_ACCESS_UPDATE_RESET })
      dispatch({ type: LAB_MANAGER_GET_LABS_USERS_RESET })
      setShow(false)
    }
  }

  const checkChecked = (labUser) => {
    return grantedLabMemberIds.includes(labUser._id)
  }

  return (
    <>
      {userInfo && userInfo.isLabManager && (
        <Button variant='secondary' className='btn-sm m-1' onClick={handleOpen}>
          Modify Study Access for Lab Users
        </Button>
      )}

      <ModalContainer
        title={`Modify Study Access for Lab Users (Study: ${studyName})`}
        show={show}
        showClose={!loadingUpdateStudyAccess}
        handleSave={handleUpdateStudyAccess}
        handleClose={handleClose}
        showSave={successGetLabUsers}
      >
        {errorUpdateStudyAccess && (
          <Message variant='danger'>{errorUpdateStudyAccess}</Message>
        )}
        {errorGetLabUsers && (
          <Message variant='danger'>{errorGetLabUsers}</Message>
        )}
        {errorGetStudyAccessUsers && (
          <Message variant='danger'>{errorGetStudyAccessUsers}</Message>
        )}

        {loadingGetLabUsers ||
        loadingGetStudyAccessUsers ||
        loadingUpdateStudyAccess ? (
          <Loader />
        ) : (
          !logout &&
          labUsers && (
            <>
              <p>
                Please add or remove study access for your lab users below and
                click 'Save Changes' to apply changes.
              </p>
              <Table
                striped
                bordered
                hover
                responsive
                className='table-sm'
                style={{ marginTop: '10px' }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>EMAIL</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Particpants here are with participation filtered */}
                  {labUsers
                    .filter((x) => !x.isLabManager)
                    .map((labUser, ind) => (
                      <tr key={labUser._id}>
                        <td className='align-middle'>
                          <Form.Group
                            controlId={`${labUser._id}_access_select_checkbox`}
                          >
                            <Form.Check
                              type='checkbox'
                              checked={checkChecked(labUser)}
                              // for checkbox it is e.target.checked
                              onChange={(e) => {
                                checkLabMemberHandler(
                                  e,
                                  ind,
                                  labUsers.filter((x) => !x.isLabManager)
                                )
                              }}
                            ></Form.Check>
                          </Form.Group>
                        </td>
                        <td className='align-middle'>{labUser.email}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          )
        )}
      </ModalContainer>
    </>
  )
}

export default AssignAdminAccess
