import axios from 'axios'
import { USER_LOGOUT } from '../../admin_module/constants/userConstants'
import {
  DIARY_AX_TASK_LIST_FAIL,
  DIARY_AX_TASK_LIST_REQUEST,
  DIARY_AX_TASK_LIST_SUCCESS,
} from '../constants/diaryAxConstants'

import BACKEND_URL from '../../backendUrl'

const standardErrorHandling = (dispatch, error, errorConstant) => {
  if (error.response && error.response.status === 401) {
    // This ensure userInfo is clear
    dispatch({
      type: USER_LOGOUT,
    })
    localStorage.removeItem('adminModuleUserInfo')
  } else {
    // In the custom express error handler, there could be custom error message,
    //  which is stored in error.response as { message: ..., stack: ... }
    // If there is no such custom error message, output the generic error message (i.e. error.message)
    dispatch({
      type: errorConstant,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Following are the actions that will be fired off after calling by components
export const getDiaryAxTaskList =
  (studyId, participantId) => async (dispatch, getState) => {
    try {
      dispatch({ type: DIARY_AX_TASK_LIST_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        BACKEND_URL + `/api/diary_ax_tasks/${studyId}/${participantId}`,
        config
      )

      let diaryAxTasksOut = data.diaryAxTasks.map((x) => {
        return { ...x, number_of_days_left: data.daysLeft[x._id] }
      })
      dispatch({
        type: DIARY_AX_TASK_LIST_SUCCESS,
        payload: { diaryAxTasks: diaryAxTasksOut },
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, DIARY_AX_TASK_LIST_FAIL)
    }
  }
