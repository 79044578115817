////////////////////////////////////////////////////////
//The onResponseDelete handler on this page demostrates how
//   child component's function can be flexibly pass to its parent
////////////////////////////////////////////////////////
import React, { useState, useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import ToastMessage from '../components/ToastMessage'
import Loader from '../components/Loader'
import Question from '../components/Question'
import ObjectId from '../utils/ObjectId'

import { Row, Col, Button, Form } from 'react-bootstrap'
import {
  MC_DETAILS_RESET,
  QX_DETAILS_RESET,
  QX_EDIT_RESET,
} from '../constants/qxConstants'
import { editQx, listQxDetails, listMc } from '../actions/qxActions'
import ModifyMultipleChoice from '../components/ModifyMultipleChoice'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'

const QxEditScreen = ({ match, history }) => {
  const qxId = match.params.id

  //////// generate unique id from index /////////

  const generateUidFromIndex = (index) => {
    return ObjectId()
  }

  //////// Handle toast message ///////////

  const [toastMessages, setToastMessages] = useState([])

  const toastOnCloseHandler = async (uid) => {
    setToastMessages((toastMessages) =>
      toastMessages.filter((message) => message.uid !== uid)
    )
  }

  /////////////////////////////////////////

  const questionsRef = useRef()

  const [abbrev, setAbbrev] = useState('')
  const [name, setName] = useState('')
  const [qx, setQx] = useState({
    _id: '',
    chin_name: '',
    abbrev: '',
    chin_content: [],
  })
  const [saveError, setSaveError] = useState('')
  const [goToQuestionList, setGoToQuestionList] = useState([])

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const qxDetails = useSelector((state) => state.qxDetails)
  const {
    loading: loadingInitialQx,
    error: errorInitialQx,
    qx: updatedQx,
    success: successInitialQx,
  } = qxDetails

  const qxEdit = useSelector((state) => state.qxEdit)
  const {
    loading: loadingEditQx,
    error: errorEditQx,
    success: successEditQx,
  } = qxEdit

  const qxMcList = useSelector((state) => state.mcList)
  const {
    loading: loadingInitialMcList,
    error: errorInitialMcList,
    mcList: updatedMcList,
    success: successInitialMcList,
  } = qxMcList

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      dispatch({ type: QX_DETAILS_RESET })
      history.push('/login')
    } else {
      if (!loadingInitialMcList && !errorInitialMcList) {
        if (!successInitialMcList) {
          dispatch(listMc(qxId))
        }
      }
      if (!loadingInitialQx && !errorInitialQx) {
        if (!successInitialQx) {
          dispatch(listQxDetails(qxId))
        } else {
          setAbbrev(updatedQx.abbrev)
          setName(updatedQx.chin_name)
          setQx(updatedQx)
          setGoToQuestionList(getGoToPositions(updatedQx))
        }
      }

      if (successEditQx) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: 'Changes have been saved',
          },
        ])
        dispatch({ type: QX_EDIT_RESET })
      }
    }
  }, [
    dispatch,
    history,
    qx,
    qxId,
    userInfo,
    logout,
    updatedQx,
    updatedMcList,
    loadingInitialQx,
    loadingInitialMcList,
    errorInitialQx,
    errorInitialMcList,
    successInitialQx,
    successEditQx,
    successInitialMcList,
  ])

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  const removeNonMcField = (qx) => {
    for (let i = 0; i < qx.chin_content.length; ++i) {
      for (let j = 0; j < qx.chin_content[i].other_info.length; ++j) {
        if (qx.chin_content[i].other_info[j].type !== 'mc') {
          if ('choices' in qx.chin_content[i].other_info[j]) {
            qx.chin_content[i].other_info[j].choices = undefined
          }
        }
      }
    }
  }

  const updateEarlierThan = (qx, becomeNotTimeQIndex, becomeNotTimeRIndex) => {
    for (let i = 0; i < qx.chin_content.length; ++i) {
      for (let j = 0; j < qx.chin_content[i].other_info.length; ++j) {
        const validation = qx.chin_content[i].other_info[j].validation
        if (validation.earlier) {
          if (
            Number(validation.earlier.target_qpos) ===
              Number(becomeNotTimeQIndex + 1) &&
            Number(validation.earlier.target_rpos) ===
              Number(becomeNotTimeRIndex + 1)
          ) {
            delete validation.earlier
          }
        }
      }
    }
  }

  const updateDependencies = (
    qx,
    qIndex,
    addedQIndex = undefined,
    addedRIndex = undefined,
    removedQIndex = undefined,
    removedRIndex = undefined,
    updatedTypeRIndex = undefined // For cases with updated response type
  ) => {
    for (let i = 0; i < qx.chin_content.length; ++i) {
      if (qx.chin_content[i].dependency.length > 0) {
        for (
          let k = qx.chin_content[i].dependency[0].or.length - 1;
          k >= 0;
          --k
        ) {
          const dependencyQPos = Number(
            qx.chin_content[i].dependency[0].or[k].dependent_qpos
          )
          const dependencyRPos = Number(
            qx.chin_content[i].dependency[0].or[k].dependent_response_pos
          )
          // Add question
          if (addedQIndex !== undefined) {
            if (dependencyQPos >= Number(addedQIndex + 1)) {
              // increment question position
              qx.chin_content[i].dependency[0].or[k].dependent_qpos =
                dependencyQPos + 1
            }
          }

          // Add response item
          if (addedRIndex !== undefined) {
            if (dependencyQPos === Number(qIndex + 1)) {
              if (dependencyRPos >= Number(removedRIndex + 1)) {
                // increment response position
                qx.chin_content[i].dependency[0].or[k].dependent_response_pos =
                  dependencyRPos + 1
              }
            }
          }

          // Remove question
          if (removedQIndex !== undefined) {
            if (dependencyQPos === Number(removedQIndex + 1)) {
              // Remove dependency for removed response item
              qx.chin_content[i].dependency[0].or.splice(k, 1)
            }
            if (dependencyQPos > Number(removedQIndex + 1)) {
              // decrement question position
              qx.chin_content[i].dependency[0].or[k].dependent_qpos =
                dependencyQPos - 1
            }
          }

          // Remove response item
          if (removedRIndex !== undefined) {
            if (dependencyQPos === Number(qIndex + 1)) {
              if (dependencyRPos === Number(removedRIndex + 1)) {
                // Remove dependency for removed response item
                qx.chin_content[i].dependency[0].or.splice(k, 1)
              }
              if (dependencyRPos > Number(removedRIndex + 1)) {
                // decrement response position
                qx.chin_content[i].dependency[0].or[k].dependent_response_pos =
                  dependencyRPos - 1
              }
            }
          }

          if (updatedTypeRIndex !== undefined) {
            if (dependencyQPos === Number(qIndex + 1)) {
              if (dependencyRPos === Number(updatedTypeRIndex + 1)) {
                // Remove dependency for updated response type
                qx.chin_content[i].dependency[0].or.splice(k, 1)
              }
            }
          }
        }
        if (qx.chin_content[i].dependency[0].or.length === 0) {
          qx.chin_content[i].dependency = []
        }
      }
    }
  }

  const saveHandler = () => {
    removeNonMcField(updatedQx)
    console.log('updated', updatedQx)
    setSaveError('')
    let error = ''
    if (error.length === 0) error = checkQuestionNumNonEmpty()
    if (error.length === 0) error = checkMcNonEmpty()
    if (error.length === 0) error = checkSliderNonEmpty()
    if (error.length === 0) error = checkSliderMinMax()
    if (error.length === 0) error = checkSliderDefaultWithinMaxMin()
    if (error.length === 0) error = checkRequrement()

    if (error.length === 0) {
      dispatch(editQx(qxId, updatedQx))
    } else {
      setSaveError(error)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  //////////  Validators ///////////

  const checkQuestionNumNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedQx.chin_content.length; ++i) {
      if (
        updatedQx.chin_content[i].other_info[0].type !== 'instruction' &&
        updatedQx.chin_content[i].other_info[0].type !== 'media' &&
        updatedQx.chin_content[i].question_num.length === 0
      ) {
        error_msg = 'Question number cannot be empty'
        return error_msg
      }
    }
    return error_msg
  }

  const checkRequrement = () => {
    let error_msg = ''
    for (let i = 0; i < updatedQx.chin_content.length; ++i) {
      if (updatedQx.chin_content[i].other_info[0].validation.earlier) {
        if (
          updatedQx.chin_content[i].other_info[0].validation.earlier
            .target_qpos === -1
        ) {
          error_msg = 'Select a question in submission requirement'
          return error_msg
        }
      }
      if (updatedQx.chin_content[i].other_info[0].validation.earlier) {
        if (
          updatedQx.chin_content[i].other_info[0].validation.earlier
            .target_rpos === -1
        ) {
          error_msg = 'Select a response in submission requirement'
          return error_msg
        }
      }
    }
    return error_msg
  }

  const checkMcNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedQx.chin_content.length; ++i) {
      if (
        updatedQx.chin_content[i].other_info[0].type === 'mc' &&
        !updatedQx.chin_content[i].other_info[0].choices
      ) {
        error_msg =
          'Please specifiy multiple choices set for multiple choice type question'
        return error_msg
      }
    }
    return error_msg
  }

  const checkSliderNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedQx.chin_content.length; ++i) {
      if (
        updatedQx.chin_content[i].other_info[0].type === 'slider' &&
        (updatedQx.chin_content[i].other_info[0].min?.length === 0 ||
          updatedQx.chin_content[i].other_info[0].max?.length === 0 ||
          updatedQx.chin_content[i].other_info[0].min_label?.length === 0 ||
          updatedQx.chin_content[i].other_info[0].max_label?.length === 0)
      ) {
        error_msg = 'All the settings of slider can not be empty'
        return error_msg
      }
    }
    return error_msg
  }

  const checkSliderMinMax = () => {
    let error_msg = ''
    for (let i = 0; i < updatedQx.chin_content.length; ++i) {
      if (
        updatedQx.chin_content[i].other_info[0].type === 'slider' &&
        updatedQx.chin_content[i].other_info[0].min >=
          updatedQx.chin_content[i].other_info[0].max
      ) {
        error_msg = 'Slider maximum value must be larger than minimum value'
        return error_msg
      }
    }
    return error_msg
  }

  const checkSliderDefaultWithinMaxMin = () => {
    let error_msg = ''
    for (let i = 0; i < updatedQx.chin_content.length; ++i) {
      if (
        updatedQx.chin_content[i].other_info[0].type === 'slider' &&
        (Number(updatedQx.chin_content[i].other_info[0].min) >
          Number(updatedQx.chin_content[i].other_info[0].slider_default) ||
          Number(updatedQx.chin_content[i].other_info[0].max) <
            Number(updatedQx.chin_content[i].other_info[0].slider_default))
      ) {
        console.log(updatedQx.chin_content[i].other_info[0])
        error_msg =
          'Slider default value can not be greater than max value or less than min value'
        return error_msg
      }
    }
    return error_msg
  }

  //////////////////////////////////

  ///////// Handle responses ////////
  const deleteResponseHandler = (index, rIndex) => {
    let tempQx = { ...updatedQx }

    // Update dependency if needed
    updateDependencies(
      tempQx,
      index,
      undefined,
      undefined,
      undefined,
      rIndex,
      undefined
    )

    tempQx.chin_content[index].other_info.splice(rIndex, 1)
    // To make sure the position is in correct order
    for (let j = 0; j < tempQx.chin_content[index].other_info.length; ++j) {
      tempQx.chin_content[index].other_info[j].position = j + 1
    }

    setQx(tempQx)
  }

  const changeResponseTypeHandler = (
    index,
    rIndex,
    originalType,
    updatedType
  ) => {
    let tempQx = { ...updatedQx }

    // Dependencies are needed to be updated as settings will not be applicable
    updateDependencies(
      tempQx,
      index,
      undefined,
      undefined,
      undefined,
      undefined,
      rIndex
    )

    // Update earlier than validation if change from time to something else
    if (originalType === 'time' && originalType !== updatedType) {
      updateEarlierThan(tempQx, index, rIndex)
    }

    // If chagne to time, update other info to set default values
    tempQx.chin_content[index].other_info[rIndex].instruction_msg =
      '請選擇時間...'
    tempQx.chin_content[index].other_info[rIndex].picker_format = 'hh mm A'

    setQx(tempQx)
  }

  const addTextResponseHandler = (index) => {
    let tempQx = { ...updatedQx }
    let newResponseTemplate = {
      _id: generateUidFromIndex(index),
      position: index + 1,
      type: 'text',
      pre_label: '',
      post_label: '',
      number_only: false,
      validation: { required: true },
    }

    const targetIndex = tempQx.chin_content[index].other_info.length

    // Update dependency if needed
    /*
     (In the current it is not needed, because it is always appended at the end)
     updateDependencies(
      tempQx,
      index,
      undefined,
      targetIndex,
      undefined,
      undefined,
      undefined
    )*/

    tempQx.chin_content[index].other_info.splice(
      targetIndex,
      0,
      newResponseTemplate
    )

    setQx(tempQx)
  }

  //////////////////////////////////

  ///////// Handle questions ////////

  const deleteQuestionHandler = (index) => {
    let tempQx = { ...updatedQx }
    let targetEl = undefined

    // Update dependency if needed
    updateDependencies(
      tempQx,
      index,
      undefined,
      undefined,
      index,
      undefined,
      undefined
    )

    tempQx.chin_content.splice(index, 1)
    // To make sure the position is in correct order
    for (let i = 0; i < tempQx.chin_content.length; ++i) {
      tempQx.chin_content[i].position = i + 1
    }
    setQx(tempQx)

    // For scrolling to the element after the deleted one
    if (index <= tempQx.chin_content.length - 1) {
      targetEl = questionsRef.current.children[index]
      window.scroll({
        top: targetEl.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  const addQuestionHandler = async (index, next) => {
    let newQuestionTemplate = {
      _id: generateUidFromIndex(index),
      question_num: '',
      html_question: '(Please Edit)',
      html_guide: 'Please Edit',
      position: 1,
      dependency: [],
      other_info: [
        {
          type: 'mc',
          position: 1,
          choices: [],
          validation: { required: true },
        },
      ],
      guide: '',
    }

    let tempQx = { ...updatedQx }
    let insertedIndex = undefined
    let targetEl = undefined

    if (next) {
      insertedIndex = index + 1
    } else {
      //add to prev
      insertedIndex = index
    }

    // Update dependency if needed
    updateDependencies(
      tempQx,
      insertedIndex,
      insertedIndex,
      undefined,
      undefined,
      undefined,
      undefined
    )

    tempQx.chin_content.splice(insertedIndex, 0, newQuestionTemplate)
    // To make sure the position is in correct order
    for (let i = 0; i < tempQx.chin_content.length; ++i) {
      tempQx.chin_content[i].position = i + 1
    }
    setQx(tempQx)

    // For scrolling to the added element (except the last one)
    if (insertedIndex !== tempQx.chin_content.length - 1) {
      targetEl = questionsRef.current.children[insertedIndex]
      window.scroll({
        top: targetEl.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  ///////// Handle go to question ////////

  const getGoToPositions = (updatedQx) => {
    let output = []
    for (let i = 0; i < updatedQx.chin_content.length; ++i) {
      if (updatedQx.chin_content[i].question_num.length > 0) {
        output.push({
          id: 'go_to_question_' + i,
          name: 'Question ' + updatedQx.chin_content[i].question_num,
          value: i,
        })
      }
    }
    return output
  }

  const onQuestionNumChangeHandler = () => {
    setGoToQuestionList(getGoToPositions(updatedQx))
  }

  //////////////////////////////////

  //// Handle multiple choices set /////////

  const multipleChoicesSetUpdatedHandler = () => {
    dispatch({ type: MC_DETAILS_RESET })
    dispatch(listMc(qxId))
    setToastMessages((toastMessages) => [
      ...toastMessages,
      {
        uid: new Date().valueOf(),
        variant: 'info',
        message: 'Multiple choices set has been updated',
      },
    ])
  }

  const multipleChoicesSetCreatedHandler = () => {
    dispatch({ type: MC_DETAILS_RESET })
    dispatch(listMc(qxId))
    setToastMessages((toastMessages) => [
      ...toastMessages,
      {
        uid: new Date().valueOf(),
        variant: 'info',
        message: `A new multiple choices set has been created`,
      },
    ])
  }

  const multipleChoicesSetDeletedHandler = () => {
    dispatch({ type: MC_DETAILS_RESET })
    dispatch(listMc(qxId))
    setToastMessages((toastMessages) => [
      ...toastMessages,
      {
        uid: new Date().valueOf(),
        variant: 'info',
        message: `Multiple choices set has been removed`,
      },
    ])
  }

  /////////////////////////////////////////
  return (
    <>
      {/* Display toast messages */}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          x: '0',
          y: '0',
          height: '80vh',
          width: '80vw',
          zIndex: 1,
        }}
      >
        {toastMessages.map((toastMessage) => (
          <ToastMessage
            key={toastMessage.uid}
            variant={toastMessage.variant}
            onClose={() => toastOnCloseHandler(toastMessage.uid)}
          >
            {toastMessage.message}
          </ToastMessage>
        ))}
      </div>

      {/* Sticky bar for saving changes */}
      <Row
        style={{
          position: 'fixed',
          backgroundColor: 'white',
          bottom: 0,
          height: '50px',
          width: '95%',
          zIndex: 1,
        }}
      >
        <Col>
          {loadingEditQx ? (
            <Loader />
          ) : (
            <Button
              onClick={saveHandler}
              className='my-1 btn-success'
              style={{ float: 'left', marginLeft: '10px' }}
            >
              Save Changes
            </Button>
          )}

          <div
            style={{
              marginLeft: '10px',
              marginTop: '8px',
              fontSize: '16px',
              float: 'left',
            }}
          >
            {'      '}
            Go to:{' '}
          </div>
          <Form.Group
            style={{
              marginLeft: '10px',
              marginRight: '10px',
              marginTop: '3px',
              float: 'left',
            }}
          >
            <Form.Control
              as='select'
              onChange={(e) => {
                let targetEl = questionsRef.current.children[e.target.value]
                window.scroll({
                  top: targetEl.offsetTop,
                  behavior: 'smooth',
                })
              }}
            >
              {goToQuestionList.map((questionPosition) => (
                <option
                  value={questionPosition.value}
                  key={questionPosition.id}
                >
                  {questionPosition.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {successInitialMcList && (
            <ModifyMultipleChoice
              mcList={updatedMcList}
              qId={qxId}
              onUpdate={multipleChoicesSetUpdatedHandler}
              onCreate={multipleChoicesSetCreatedHandler}
              onDelete={multipleChoicesSetDeletedHandler}
              style={{ float: 'left' }}
            ></ModifyMultipleChoice>
          )}
        </Col>
      </Row>

      <Row style={{ height: '60px' }} className='align-items-center'>
        <Col>
          <h1 style={headingStyle}>Edit Questionnaire</h1>
        </Col>
        <Col>
          {errorInitialQx && (
            <Message variant='danger'>{errorInitialQx}</Message>
          )}
          {errorEditQx && <Message variant='danger'>{errorEditQx}</Message>}
          {saveError && <Message variant='danger'>{saveError}</Message>}
        </Col>
      </Row>
      <Row className='mx-2'>
        <Col md={6}>
          <Form.Group controlId={'qx_abbrev'}>
            <Form.Label style={{ whiteSpace: 'normal' }}>
              Questionniare Code
            </Form.Label>
            <Form.Control
              type='text'
              value={abbrev}
              onChange={(e) => {
                qx.abbrev = e.target.value
                setAbbrev(qx.abbrev)
              }}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId={'qx_name'}>
            <Form.Label style={{ whiteSpace: 'normal' }}>
              Questionniare Name
            </Form.Label>
            <Form.Control
              type='text'
              value={name}
              onChange={(e) => {
                qx.chin_name = e.target.value
                setName(qx.chin_name)
              }}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      {successInitialMcList && (
        <Row className='mt-2' style={{ marginBottom: '50px' }}>
          <Col ref={questionsRef}>
            {qx.chin_content.map((question, index) => (
              <Question
                qx={qx}
                mcList={updatedMcList}
                question={question}
                index={question._id}
                i={index}
                key={question._id}
                onAddPrev={() => addQuestionHandler(index, false)}
                onAddNext={() => addQuestionHandler(index, true)}
                onAddTextResponse={() => addTextResponseHandler(index)}
                onQuestionDelete={() => deleteQuestionHandler(index)}
                onResponseDelete={(rIndex) =>
                  deleteResponseHandler(index, rIndex)
                }
                onResponseTypeChange={(originalType, updatedType, rIndex) =>
                  changeResponseTypeHandler(
                    index,
                    rIndex,
                    originalType,
                    updatedType
                  )
                }
                onQuestionNumChange={(position, questionNum) =>
                  onQuestionNumChangeHandler(position, questionNum)
                }
                deleteAvailable={qx.chin_content.length > 1}
              ></Question>
            ))}
          </Col>
        </Row>
      )}
    </>
  )
}

export default QxEditScreen
