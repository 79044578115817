import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form, Table } from 'react-bootstrap'
import DownloadTextResponseModalContainer from './DownloadTextResponseModalContainer'
import Loader from './Loader'
import Message from './Message'

import {
  getAllPartiList,
  downloadTextResp,
} from '../actions/participantActions'

import { DOWNLOAD_TEXT_RESP_RESET } from '../constants/participantConstants'

export const DownloadTextResponse = ({
  studyId,
  selectedStage,
  selectedGroup,
}) => {
  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const downloadTextRespState = useSelector((state) => state.downloadTextResp)
  const {
    error: errorDownloadTextResp,
    loading: loadingDownloadTextResp,
    success: successDownloadTextResp,
  } = downloadTextRespState

  const allPartiList = useSelector((state) => state.allPartiList)
  const {
    participantsData: allParticipantsData,
    error: errorGetAllPartiList,
    loading: loadingGetAllPartiList,
    success: successGetAllPartiList,
  } = allPartiList

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  const [warningText, setWarningText] = useState('')

  const [excludedParticipantsIds, setExcludedParticipantsIds] = useState([])

  const [keepDuplicate, setKeepDuplicate] = useState(false)

  // For shift select or deselect
  const [lastUpdatedParticipantInd, setLastUpdatedParticipantInd] = useState(0) // ind stands for the index of the current retrieved participants

  useEffect(() => {
    if (successDownloadTextResp) {
      dispatch({ type: DOWNLOAD_TEXT_RESP_RESET })
    }
  }, [dispatch, successDownloadTextResp])

  const updateParticipantSelection = (
    tempExcludedParticipantsIds,
    checked,
    participantId
  ) => {
    if (checked) {
      if (!tempExcludedParticipantsIds.find((x) => x === participantId)) {
        tempExcludedParticipantsIds.push(participantId)
      }
    } else {
      tempExcludedParticipantsIds = tempExcludedParticipantsIds.filter(
        (x) => x !== participantId
      )
    }
    return tempExcludedParticipantsIds
  }

  const checkParticipantHandler = (e, ind, participants) => {
    let tempExcludedParticipantsIds = [...excludedParticipantsIds]

    if (e.nativeEvent.shiftKey) {
      // For bulk selection when pressing shift key
      if (ind >= lastUpdatedParticipantInd) {
        for (let i = lastUpdatedParticipantInd; i <= ind; ++i) {
          tempExcludedParticipantsIds = updateParticipantSelection(
            tempExcludedParticipantsIds,
            e.target.checked,
            participants[i]._id
          )
        }
      } else {
        for (let i = lastUpdatedParticipantInd; i >= ind; --i) {
          tempExcludedParticipantsIds = updateParticipantSelection(
            tempExcludedParticipantsIds,
            e.target.checked,
            participants[i]._id
          )
        }
      }
    } else {
      // For single selection
      tempExcludedParticipantsIds = updateParticipantSelection(
        tempExcludedParticipantsIds,
        e.target.checked,
        participants[ind]._id
      )
    }

    setLastUpdatedParticipantInd(ind)
    setExcludedParticipantsIds(tempExcludedParticipantsIds)
    if (tempExcludedParticipantsIds.length > 0) {
      setWarningText(
        `(${tempExcludedParticipantsIds.length} participant(s) excluded)`
      )
    } else {
      setWarningText('')
    }
  }

  const handleOpen = () => {
    let now = new Date()
    if (userInfo && userInfo.expires > now && !logout) {
      if (
        !loadingGetAllPartiList &&
        !errorGetAllPartiList &&
        !successGetAllPartiList
      ) {
        // Reset the participant selection after reloading the participant list
        setExcludedParticipantsIds([])
        setLastUpdatedParticipantInd(0)
        dispatch(getAllPartiList(studyId))
      }
    }
    setShow(true)
  }

  const handleDownload = async () => {
    dispatch(
      downloadTextResp(
        studyId,
        selectedStage,
        selectedGroup,
        excludedParticipantsIds,
        !keepDuplicate
      )
    )
  }

  const handleClose = () => {
    if (!loadingDownloadTextResp) {
      setShow(false)
    }
  }

  return (
    <>
      <Button variant='primary' className='mt-2 mr-2' onClick={handleOpen}>
        Download Text Response
      </Button>

      <DownloadTextResponseModalContainer
        title='Download Text Response in the Selected Stage and Participant Group'
        show={show}
        showClose={!loadingDownloadTextResp}
        handleDownload={handleDownload}
        handleClose={handleClose}
        warningText={warningText}
        isLoading={loadingDownloadTextResp}
      >
        {errorDownloadTextResp && (
          <Message variant='danger'>{errorDownloadTextResp}</Message>
        )}
        {errorGetAllPartiList && (
          <Message variant='danger'>{errorGetAllPartiList}</Message>
        )}

        {loadingGetAllPartiList ? (
          <Loader />
        ) : (
          !logout &&
          allParticipantsData &&
          allParticipantsData.participants && (
            <>
              {allParticipantsData.participants.length > 0 ? (
                <>
                  <p>
                    Note: Data export may fail if you export huge amount of data
                    in a single download.
                  </p>
                  <p>
                    The table below lists all the participants of this study,
                    including ones who had not submitted any data in the
                    selected participant group. If you do not want to export all
                    the data at a time, you can select participants below to{' '}
                    <span style={{ color: 'red' }}>exclude</span> them from the
                    exported data.
                  </p>
                  <p>
                    Tips: You can hold the 'Shift' key and check/uncheck a
                    participant to perform bulk selection/deselection. Bulk
                    selection/deselection will be applied from the last
                    selected/deselected participant to the current
                    selected/deselected participant.
                  </p>
                  <Row>
                    <Col className='d-flex justify-content-end'>
                      <Form.Group controlId={`keep_duplicate_checkbox`}>
                        <Form.Check
                          type='checkbox'
                          checked={keepDuplicate}
                          // for checkbox it is e.target.checked
                          onChange={(e) => {
                            setKeepDuplicate(e.target.checked)
                          }}
                          label='Keep Duplicate Data'
                        ></Form.Check>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className='table-sm'
                    style={{ marginTop: '10px' }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>PARTICIPANT ID</th>
                        <th>EMAIL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Particpants here are with participation filtered */}
                      {allParticipantsData.participants.map(
                        (participant, ind) => (
                          <tr key={participant._id}>
                            <td className='align-middle'>
                              <Form.Group
                                controlId={`${participant._id}_select_checkbox`}
                              >
                                <Form.Check
                                  type='checkbox'
                                  checked={excludedParticipantsIds.includes(
                                    participant._id
                                  )}
                                  // for checkbox it is e.target.checked
                                  onChange={(e) => {
                                    checkParticipantHandler(
                                      e,
                                      ind,
                                      allParticipantsData.participants
                                    )
                                  }}
                                ></Form.Check>
                              </Form.Group>
                            </td>
                            <td className='align-middle'>
                              {participant.participant_code}
                            </td>
                            <td className='align-middle'>
                              {participant.email}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </>
              ) : (
                <Row className='align-items-center my-3'>
                  <Col>
                    <h4>No participant has enroled this study</h4>
                  </Col>
                </Row>
              )}
            </>
          )
        )}
      </DownloadTextResponseModalContainer>
    </>
  )
}

export default DownloadTextResponse
