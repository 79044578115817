import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import ToastMessage from '../components/ToastMessage'
import Loader from '../components/Loader'
import StudyBasic from '../components/StudyBasic'
import StudyConsent from '../components/StudyConsent'
import StudyStage from '../components/StudyStage'

import { Row, Col, Card, Button } from 'react-bootstrap'

import {
  addStage,
  editStudyExceptConsent,
  listStudyDetails,
} from '../actions/studyActions'
import { getQxList } from '../../qx_module/actions/qxActions'
import {
  PARTI_GROUP_ADD_RESET,
  PARTI_GROUP_DELETE_RESET,
  STAGE_ADD_RESET,
  STAGE_DELETE_RESET,
  STUDY_DETAILS_RESET,
  STUDY_EDIT_CONSENT_RESET,
  STUDY_EDIT_EXCEPT_CONSENT_RESET,
} from '../constants/studyConstants'
import resetErrorState from '../utils/resetErrorState'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'
import { getAllAccess } from '../../access_module/actions/accessActions'
import { GET_ALL_ACCESS_RESET } from '../../access_module/constants/accessConstants'

const StudyEditScreen = ({ match, history }) => {
  // For toast message
  const [toastMessages, setToastMessages] = useState([])

  const toastOnCloseHandler = async (uid) => {
    setToastMessages((toastMessages) =>
      toastMessages.filter((message) => message.uid !== uid)
    )
  }

  const studyId = match.params.id

  const [study, setStudy] = useState({
    _id: '',
    study_code: '',
    active_status: '',
    chin_name: '',
    contact_person_name: '',
    contact_email: '',
    stage_list: [
      {
        chin_name: '',
        stage_num: 1,
        consent_info: {
          consent_needed: true,
          chin_content: '',
        },
        chin_complete_message: '',
        //participant_group was previously named as ax_version
        participant_group_list: [
          {
            name: '',
            group_num: 1,
            ema_list: [],
            diary_list: [],
            questionnaire_list: [],
          },
        ],
      },
    ],
  })

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const studyDetails = useSelector((state) => state.studyDetails)
  const {
    loading: loadingInitiateStudy,
    error: errorInitiateStudy,
    study: updatedStudy,
    success: successInitiateStudy,
  } = studyDetails

  ////studyEditConsent state can be altered after form in StudyConsent componet is submitted
  const studyEditConsent = useSelector((state) => state.studyEditConsent)
  const { error: errorEditConsent, success: successEditConsent } =
    studyEditConsent

  const stageAdd = useSelector((state) => state.stageAdd)
  const { error: errorStageAdd, success: successStageAdd } = stageAdd
  const stageDelete = useSelector((state) => state.stageDelete)
  const { error: errorStageDelete, success: successStageDelete } = stageDelete

  const participantGroupAdd = useSelector((state) => state.participantGroupAdd)
  const {
    error: errorParticipantGroupAdd,
    success: successParticipantGroupAdd,
  } = participantGroupAdd
  const participantGroupDelete = useSelector(
    (state) => state.participantGroupDelete
  )
  const {
    error: errorParticipantGroupDelete,
    success: successParticipantGroupDelete,
  } = participantGroupDelete

  const qxList = useSelector((state) => state.qxList)
  const {
    loading: loadingQxList,
    error: errorQxList,
    qx_list: initialQxList,
    success: successQxList,
  } = qxList

  const studyEditExceptConsent = useSelector(
    (state) => state.studyEditExceptConsent
  )
  const {
    loading: loadingEditStudy,
    error: errorEditStudy,
    success: successEditStudy,
  } = studyEditExceptConsent

  const allAccess = useSelector((state) => state.allAccess)
  const {
    loading: laodingGetAllAccess,
    error: errorGetAllAccess,
    success: successGetAllAccess,
    allAccessList,
  } = allAccess

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      dispatch({ type: STUDY_DETAILS_RESET })
      history.push('/login')
    } else {
      if (!loadingQxList && !errorQxList && !successQxList) {
        dispatch(getQxList())
      }

      if (!laodingGetAllAccess && !successGetAllAccess && !errorGetAllAccess) {
        dispatch(getAllAccess())
      }

      if (!loadingInitiateStudy && !errorInitiateStudy) {
        resetErrorState(dispatch)
        if (!successInitiateStudy) {
          // Put the reset for getting all access just before loading the main content again
          dispatch({ type: GET_ALL_ACCESS_RESET })
          dispatch(listStudyDetails(studyId))
        } else {
          setStudy(updatedStudy)
        }
      }

      if (successEditConsent) {
        window.scrollTo(0, 0)
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: 'Consent Form Updated',
          },
        ])
        dispatch({ type: STUDY_EDIT_CONSENT_RESET })
      }

      if (successStageAdd) {
        window.scrollTo(0, 0)
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: 'Stage Added',
          },
        ])
        dispatch({ type: STAGE_ADD_RESET })
      }

      if (successStageDelete) {
        window.scrollTo(0, 0)
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: 'Stage Deleted',
          },
        ])
        dispatch({ type: STAGE_DELETE_RESET })
      }

      if (successParticipantGroupAdd) {
        window.scrollTo(0, 0)
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: 'Participant Group Added',
          },
        ])
        dispatch({ type: PARTI_GROUP_ADD_RESET })
      }

      if (successParticipantGroupDelete) {
        window.scrollTo(0, 0)
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: 'Participant Group Deleted',
          },
        ])
        dispatch({ type: PARTI_GROUP_DELETE_RESET })
      }

      if (successEditStudy) {
        window.scrollTo(0, 0)
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: 'Study information was changed successfully',
          },
        ])
        dispatch({ type: STUDY_EDIT_EXCEPT_CONSENT_RESET })
      }
    }
  }, [
    dispatch,
    history,
    study,
    studyId,
    userInfo,
    logout,
    updatedStudy,
    loadingInitiateStudy,
    loadingQxList,
    loadingEditStudy,
    laodingGetAllAccess,
    errorInitiateStudy,
    errorQxList,
    errorEditStudy,
    errorGetAllAccess,
    successInitiateStudy,
    successEditConsent,
    successStageAdd,
    successStageDelete,
    successQxList,
    successParticipantGroupAdd,
    successParticipantGroupDelete,
    successEditStudy,
    successGetAllAccess,
  ])

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  const panelStyle = {
    display: 'inline-block',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginRight: '10px',
  }

  const addStageHandler = async () => {
    if (
      window.confirm(
        'This operation will remove unsaved changes. Please save changes first if you have not done that. Are you sure you want to go ahead?'
      )
    ) {
      resetErrorState(dispatch)
      dispatch(addStage(study._id))
    }
  }

  const saveHandler = async () => {
    resetErrorState(dispatch)
    dispatch(editStudyExceptConsent(study._id, study))
  }

  return (
    <>
      {/* Display toast messages */}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          x: '0',
          y: '0',
          height: '80vh',
          width: '80vw',
          zIndex: 1,
        }}
      >
        {toastMessages.map((toastMessage) => (
          <ToastMessage
            key={toastMessage.uid}
            variant={toastMessage.variant}
            onClose={() => toastOnCloseHandler(toastMessage.uid)}
          >
            {toastMessage.message}
          </ToastMessage>
        ))}
      </div>

      {loadingInitiateStudy || loadingEditStudy || laodingGetAllAccess ? (
        <Loader />
      ) : (
        <div style={{ display: 'relative', height: '90vh', width: '100%' }}>
          <Row style={{ height: '60px' }} className='align-items-center'>
            <Col>
              <h1 style={headingStyle}>Edit Study</h1>
            </Col>
            <Col>
              {errorInitiateStudy && (
                <Message variant='danger'>{errorInitiateStudy}</Message>
              )}

              {errorEditConsent && (
                <Message variant='danger'>{errorEditConsent}</Message>
              )}

              {errorStageAdd && (
                <Message variant='danger'>{errorStageAdd}</Message>
              )}

              {errorStageDelete && (
                <Message variant='danger'>{errorStageDelete}</Message>
              )}

              {errorParticipantGroupAdd && (
                <Message variant='danger'>{errorParticipantGroupAdd}</Message>
              )}

              {errorParticipantGroupDelete && (
                <Message variant='danger'>
                  {errorParticipantGroupDelete}
                </Message>
              )}
            </Col>
          </Row>

          <div
            style={{
              display: 'block',
              width: '100%',
              height: '85%',
              overflowX: 'scroll',
              overflowY: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ ...panelStyle, width: '300px' }}>
              <Card className='p-3 rounded'>
                <StudyBasic study={study}></StudyBasic>
              </Card>
            </div>

            {study.stage_list.map((stage) => (
              <div
                style={{ ...panelStyle, width: '400px' }}
                key={stage.stage_num}
              >
                <Card className='p-3 rounded'>
                  <StudyStage
                    studyId={study._id}
                    stage={study.stage_list[stage.stage_num - 1]}
                    qxList={initialQxList}
                    key={stage.stage_num}
                  ></StudyStage>
                </Card>
              </div>
            ))}

            {allAccessList.find(
              (a) =>
                a.area === 'STUDY' &&
                a.modifier === 'ADD' &&
                a.task === 'STUDY_STAGE'
            ) && (
              <div style={{ ...panelStyle, width: '300px' }}>
                <Card className='p-3 rounded'>
                  <Button onClick={addStageHandler}>Add Stage</Button>
                </Card>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Sticky bar for saving changes */}
      <Row
        style={{
          position: 'fixed',
          backgroundColor: 'white',
          bottom: 0,
          height: '50px',
          width: '95%',
          zIndex: 1,
        }}
      >
        <Col>
          <Button
            onClick={saveHandler}
            className='my-1 btn-success'
            style={{ float: 'left', marginLeft: '10px' }}
          >
            Save Changes
          </Button>

          {allAccessList.find(
            (a) =>
              a.area === 'STUDY' &&
              a.modifier === 'EDIT' &&
              a.task === 'STUDY_CONSENT_FORM'
          ) && <StudyConsent></StudyConsent>}
        </Col>
      </Row>
    </>
  )
}

export default StudyEditScreen
