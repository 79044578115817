import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
//import StudyListScreen from './study_module/screens/StudyListScreen'
import Header from './shared_components/Header'
import SystemOwnerHeader from './shared_components/SystemOwnerHeader'
import Footer from './shared_components/Footer'
import { Container } from 'react-bootstrap'

//Admin module
import AdminLoginScreen from './admin_module/screens/AdminLoginScreen'
import LabManagerListingUserScreen from './admin_module/screens/LabManagerListingUserScreen'
import LabManagerRegisterScreen from './admin_module/screens/LabManagerRegisterAdminScreen'
import SystemOwnerLoginScreen from './admin_module/screens/SystemOwnerLoginScreen'
import SystemOwnerHomeScreen from './admin_module/screens/SystemOwnerHomeScreen'
import SystemOwnerListingUserScreen from './admin_module/screens/SystemOwnerListingUserScreen'
import SystemOwnerRegisterAdminScreen from './admin_module/screens/SystemOwnerRegisterAdminScreen'
import SystemOwnerEditUserScreen from './admin_module/screens/SystemOwnerEditUserScreen'
import SystemOwnerCreateLabScreen from './admin_module/screens/SystemOwnerCreateLabScreen'
import TermsCondScreen from './admin_module/screens/TermsCondScreen'
import PrivacyCondScreen from './admin_module/screens/PrivacyCondScreen'

//Media module
import MediaFilesScreen from './media_module/screens/MediaFilesScreen'
import CreateFileScreen from './media_module/screens/CreateFileScreen'
import MediaFileEditScreen from './media_module/screens/MediaFileEditScreen'

//Study module
import StudyListScreen from './study_module/screens/StudyListScreen'
import StudyEditScreen from './study_module/screens/StudyEditScreen'
import SystemOwnerStudyListScreen from './study_module/screens/SystemOwnerStudyListScreen'

//Qx module
import QxListScreen from './qx_module/screens/QxListScreen'
import QxEditScreen from './qx_module/screens/QxEditScreen'

//Participant module
import ParticipantListScreen from './participant_module/screens/ParticipantListScreen'
import CheckProgressScreen from './participant_module/screens/CheckProgressScreen'

//Error Page
import GenericErrorScreen from './admin_module/screens/GenericErrorScreen'

import getSubdomain from './getSubdomain'
import isSubdomainValid from './isSubdomainValid'
import redirectDueToInvalidSubdomain from './redirectDueToInvalidSubdomain'
import findSiteInfoBySubdomain from './findSiteInfoBySubdomain'
import SystemOwnerStudyEditScreen from './study_module/screens/SystemOwnerEditScreen'

//Get favicon element
function getFaviconEl() {
  return document.getElementById('favicon')
}

//Get site name element
function getSiteNameEl() {
  return document.getElementById('siteName')
}

// Generic Error
const ErrorContainer = () => (
  <Container>
    {/* Error message */}
    <Route path='/error' component={GenericErrorScreen} />
  </Container>
)

// System owner

const SystemOwnerLoginContainer = () => (
  <>
    <main className='py-3'>
      <Container>
        {/* From Study module */}
        <Route path='/systemowner/login' component={SystemOwnerLoginScreen} />
      </Container>
    </main>
  </>
)

const SystemOwnerHomeContainer = () => (
  <>
    <SystemOwnerHeader />
    <main className='py-3'>
      <Container>
        <Route path='/systemowner/home' component={SystemOwnerHomeScreen} />
      </Container>
    </main>
  </>
)

const SystemOwnerListingUserContainer = () => (
  <>
    <SystemOwnerHeader />
    <main className='py-3'>
      <Container>
        <Route
          path='/systemowner/lab/:id'
          component={SystemOwnerListingUserScreen}
        />
      </Container>
    </main>
  </>
)

const SystemOwnerRegisterAdminContainer = () => (
  <>
    <SystemOwnerHeader />
    <main className='py-3'>
      <Container>
        <Route
          path='/systemowner/register/:labId'
          component={SystemOwnerRegisterAdminScreen}
        />
      </Container>
    </main>
  </>
)

const SystemOwnerEditUserContainer = () => (
  <>
    <SystemOwnerHeader />
    <main className='py-3'>
      <Container>
        <Route
          path='/systemowner/user/:id/edit'
          component={SystemOwnerEditUserScreen}
        />
      </Container>
    </main>
  </>
)

const SystemOwnerCreateLabContainer = () => (
  <>
    <SystemOwnerHeader />
    <main className='py-3'>
      <Container>
        <Route
          path='/systemowner/createlab'
          component={SystemOwnerCreateLabScreen}
        />
      </Container>
    </main>
  </>
)

const SystemOwnerStudyListContainer = () => (
  <>
    <SystemOwnerHeader />
    <main className='py-3'>
      <Container>
        <Route
          path='/systemowner/studies/:pageNumber'
          component={SystemOwnerStudyListScreen}
          exact
        />
      </Container>
    </main>
  </>
)

const SystemOwnerStudyEditContainer = () => (
  <>
    <SystemOwnerHeader />
    <main className='py-3'>
      <Container>
        <Route
          path='/systemowner/studies/:id/:userId/edit'
          component={SystemOwnerStudyEditScreen}
          exact
        />
      </Container>
    </main>
  </>
)

// Lab manager or admin user

const LoginContainer = () => (
  <Container
    fluid
    className={'no-gutters mx-0 px-0'}
    style={{
      backgroundColor: findSiteInfoBySubdomain(getSubdomain(window))
        .primaryColor,
      minHeight: '100vh',
    }}
  >
    {/* From Admin module */}
    <Route path='/login' component={AdminLoginScreen} />
  </Container>
)

const EditStudyContainer = () => (
  <>
    <Header />
    <main className='py-3'>
      <Container>
        {/* From Study module */}
        <Route path='/studies/:id/edit' component={StudyEditScreen} exact />
      </Container>
    </main>
    {/* Remove footer just for this page and qx edit page */}
  </>
)

const EditQxContainer = () => (
  <>
    <Header />
    <main className='py-3'>
      <Container>
        {/* From Study module */}
        <Route path='/qx/:id/edit' component={QxEditScreen} exact />
      </Container>
    </main>
    {/* Remove footer just for this page and study edit page */}
  </>
)

const DefaultContainer = () => (
  <>
    <Header />
    <main className='py-3'>
      <Container>
        {/* From Admin module */}
        <Route path='/lm/get_users' component={LabManagerListingUserScreen} />
        <Route path='/lm/register' component={LabManagerRegisterScreen} />
        <Route path='/terms_cond' component={TermsCondScreen} />
        <Route path='/privacy_cond' component={PrivacyCondScreen} />

        {/* From Media module */}
        <Route path='/media/files' component={MediaFilesScreen} exact />
        <Route
          path='/media/files/:pageNumber'
          component={MediaFilesScreen}
          exact
        />
        <Route path='/media/createfile' component={CreateFileScreen} exact />
        <Route
          path='/media/files/edit/:id'
          component={MediaFileEditScreen}
          exact
        />
        {/* From Study module */}
        <Route path='/studies/:pageNumber' component={StudyListScreen} exact />

        {/* From Qx module */}
        <Route path='/qx/qx_list/:pageNumber' component={QxListScreen} exact />

        {/* From Participant module */}
        <Route
          path='/participants/:studyId'
          component={ParticipantListScreen}
          exact
        />
        <Route
          path='/participants/check_progress/:studyId'
          component={CheckProgressScreen}
          exact
        />

        {/* Default */}
        <Route path='/' exact>
          <Redirect to='/studies/1' />
        </Route>
      </Container>
    </main>
    <Footer />
  </>
)

function App() {
  const subdomain = getSubdomain(window)

  // Set the site favicon
  const faviconEl = getFaviconEl()
  faviconEl.href = findSiteInfoBySubdomain(subdomain).faviconUrl

  // Set the site name
  const siteNameEl = getSiteNameEl()
  siteNameEl.textContent =
    findSiteInfoBySubdomain(subdomain).siteName + ' Admin Portal'

  if (!isSubdomainValid(subdomain)) {
    redirectDueToInvalidSubdomain(window, subdomain)
  }

  return (
    <Router>
      <Switch>
        {/* For generic error */}
        <Route exact path='/error' component={ErrorContainer} />

        {/* For system owner */}
        <Route
          exact
          path='/systemowner/login'
          component={SystemOwnerLoginContainer}
        />
        <Route
          exact
          path='/systemowner/home'
          component={SystemOwnerHomeContainer}
        />
        <Route
          exact
          path='/systemowner/lab/:id'
          component={SystemOwnerListingUserContainer}
        />
        <Route
          exact
          path='/systemowner/register/:labId'
          component={SystemOwnerRegisterAdminContainer}
        />
        <Route
          exact
          path='/systemowner/user/:id/edit'
          component={SystemOwnerEditUserContainer}
        />
        <Route
          exact
          path='/systemowner/createlab'
          component={SystemOwnerCreateLabContainer}
        />
        <Route
          exact
          path='/systemowner/studies/:pageNumber'
          component={SystemOwnerStudyListContainer}
        />

        <Route
          exact
          path='/systemowner/studies/:id/:userId/edit'
          component={SystemOwnerStudyEditContainer}
        />

        {/* For lab manager or admin user */}
        <Route exact path='/login' component={LoginContainer} />
        <Route exact path='/studies/:id/edit' component={EditStudyContainer} />
        <Route exact path='/qx/:id/edit' component={EditQxContainer} />
        <Route component={DefaultContainer} />
      </Switch>
    </Router>
  )
}

export default App
