import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { USER_LOGOUT } from '../../admin_module/constants/userConstants'

import BACKEND_URL from '../../backendUrl'

const S3ZipperDownload = ({ studyId, participantId, onDownloadError }) => {
  const dispatch = useDispatch()

  // Download status are: 'NotStart', 'Started', 'ReadyDownload', 'NoResponse'
  const [downloadStatus, setDownloadStatus] = useState('NotStart')
  const [zipperLink, setZipperLink] = useState('')

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  useEffect(() => {}, [downloadStatus])

  const onDownloadClick = async (studyId, participantId) => {
    let now = new Date()
    if (userInfo && userInfo.expires > now && !logout) {
      try {
        //Following demostrate how to create data with axios in front-end
        //Authorization does not need to be quoted
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        setDownloadStatus('Started')

        const { data: downloadResp } = await axios.get(
          BACKEND_URL +
            `/api/data/${studyId}/${participantId}/download_img_resp`,
          config
        )

        const s3ZipperToken = downloadResp.s3ZipperToken
        const streamZipResp = downloadResp.streamZipResp

        if (streamZipResp.message === 'NoKeys Found Error') {
          // No image repsonse is found for this participant in this study
          setDownloadStatus('NoResponse')
        } else {
          const { data: getLinkResp } = await axios.post(
            BACKEND_URL + `/api/data/get_img_resp_link`,
            { s3ZipperToken, streamZipResp },
            config
          )

          setZipperLink(getLinkResp.result)
          setDownloadStatus('ReadyDownload')
        }
      } catch (error) {
        setDownloadStatus('NotStart')
        if (error.response && error.response.status === 401) {
          dispatch({
            type: USER_LOGOUT,
          })
          localStorage.removeItem('adminModuleUserInfo')
        } else {
          onDownloadError(error.message)
        }
      }
    } else {
      setDownloadStatus('NotStart')
      dispatch({
        type: USER_LOGOUT,
      })
      localStorage.removeItem('adminModuleUserInfo')
    }
  }

  const onZipLinkClick = async () => {
    let a = document.createElement('A')
    a.href = zipperLink
    document.body.appendChild(a)
    setTimeout(() => {
      a.click()
      document.body.removeChild(a)
      setDownloadStatus('NotStart')
    }, 1000)
  }

  return (
    <>
      {downloadStatus === 'NotStart' ? (
        <Button
          variant='primary'
          className='btn-sm m-1'
          onClick={() => onDownloadClick(studyId, participantId)}
        >
          Download Image Data
        </Button>
      ) : downloadStatus === 'Started' ? (
        <p className='m-1'>Downloading...</p>
      ) : downloadStatus === 'NoResponse' ? (
        <p className='m-1'>No image is found</p>
      ) : (
        <Button
          variant='primary'
          className='btn-sm m-1'
          onClick={() => onZipLinkClick()}
        >
          Click To Save File
        </Button>
      )}
    </>
  )
}

export default S3ZipperDownload
