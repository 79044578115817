import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form, Card } from 'react-bootstrap'
import ModalContainer from '../components/ModalContainer'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Message from '../components/Message'

export const QuestionnaireListInfo = ({ group, qxList }) => {
  const [show, setShow] = useState(false)

  const [selectedQxList, setSelectedQxList] = useState([])
  const [freeToChoose, setFreeToChoose] = useState(false)

  //Use it in the validator
  const [saveError, setSaveError] = useState('')

  useEffect(() => {
    setSelectedQxList(qxList)
    setFreeToChoose(group.questionnaire_list_free_to_choose)
  }, [qxList, group])

  const handleOpen = () => {
    setShow(true)
  }

  const handleClose = () => {
    group.questionnaire_list = selectedQxList.map((qx) => qx._id)
    group.questionnaire_list_free_to_choose = freeToChoose
    //TBD: handle random settings
    setShow(false)
  }

  // Dependency: react-beautiful-dnd
  const handleDrop = (droppedItem) => {
    //Ignore drop outside droppable container
    if (!droppedItem.destination) return
    let updatedList = [...selectedQxList]
    // Remove dragged item
    let [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    // Update State
    setSelectedQxList(updatedList)
  }

  return (
    <>
      <Button
        style={{ width: '100%', marginTop: '0', marginBottom: '10px' }}
        variant='primary'
        onClick={handleOpen}
      >
        Edit Order of Selected Questionnaires
      </Button>

      <ModalContainer
        title='Edit Order of Selected Questionnaires'
        show={show}
        handleClose={handleClose}
        showSave={false}
      >
        {saveError && <Message variant='danger'>{saveError}</Message>}
        <Row>
          <Col md={12}>
            <Form.Group controlId={'qx_list_info_free_to_choose' + group._id}>
              {/* Remove disabled = true when the app is updated to use this option */}
              <Form.Check
                type='checkbox'
                label='Participants are allowed to complete selected questionnaires in any order'
                checked={freeToChoose}
                disabled={true}
                onChange={(e) => {
                  setFreeToChoose(e.target.checked)
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label>Presented Order</Form.Label>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId='selected-qx-list-container'>
                {(provided) => (
                  <Card
                    className='p-3 rounded'
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {selectedQxList.map((qx, index) => (
                      <Draggable
                        key={qx._id}
                        draggableId={qx._id}
                        index={index}
                      >
                        {(provided) => (
                          <Card
                            className='p-3 rounded'
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            {qx.chin_name} ({qx.abbrev})
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Card>
                )}
              </Droppable>
            </DragDropContext>
          </Col>
        </Row>
      </ModalContainer>
    </>
  )
}

export default QuestionnaireListInfo
