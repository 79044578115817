/***************** Including system owner, lab manager and general admin user ******/

/******************* constants for users ************/

// log in (user)
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

// register (user)
export const LAB_MANAGER_REGISTER_USER_REQUEST =
  'LAB_MANAGER_REGISTER_USER_REQUEST'
export const LAB_MANAGER_REGISTER_USER_SUCCESS =
  'LAB_MANAGER_REGISTER_USER_SUCCESS'
export const LAB_MANAGER_REGISTER_USER_FAIL = 'LAB_MANAGER_REGISTER_USER_FAIL'
export const LAB_MANAGER_REGISTER_USER_RESET = 'LAB_MANAGER_REGISTER_USER_RESET'

// get lab users (lab manager)
export const LAB_MANAGER_GET_LABS_USERS_REQUEST =
  'LAB_MANAGER_GET_LABS_USERS_REQUEST'
export const LAB_MANAGER_GET_LABS_USERS_SUCCESS =
  'LAB_MANAGER_GET_LABS_USERS_SUCCESS'
export const LAB_MANAGER_GET_LABS_USERS_FAIL = 'LAB_MANAGER_GET_LABS_USERS_FAIL'
export const LAB_MANAGER_GET_LABS_USERS_RESET =
  'LAB_MANAGER_GET_LABS_USERS_RESET'

/***************** constants for system owner ********/

// login in (system owner)
export const SYSTEM_OWNER_LOGIN_REQUEST = 'SYSTEM_OWNER_LOGIN_REQUEST'
export const SYSTEM_OWNER_LOGIN_SUCCESS = 'SYSTEM_OWNER_LOGIN_SUCCESS'
export const SYSTEM_OWNER_LOGIN_FAIL = 'SYSTEM_OWNER_LOGIN_FAIL'
export const SYSTEM_OWNER_LOGOUT = 'SYSTEM_OWNER_LOGOUT'

// delete users (system owner)
export const SYSTEM_OWNER_DELETE_USER_REQUEST =
  'SYSTEM_OWNER_DELETE_USER_REQUEST'
export const SYSTEM_OWNER_DELETE_USER_SUCCESS =
  'SYSTEM_OWNER_DELETE_USER_SUCCESS'
export const SYSTEM_OWNER_DELETE_USER_FAIL = 'SYSTEM_OWNER_DELETE_USER_FAIL'

// get user details (system owner)
export const SYSTEM_OWNER_GET_USER_DETAIL_REQUEST =
  'SYSTEM_OWNER_GET_USER_DETAIL_REQUEST'
export const SYSTEM_OWNER_GET_USER_DETAIL_SUCCESS =
  'SYSTEM_OWNER_GET_USER_DETAIL_SUCCESS'
export const SYSTEM_OWNER_GET_USER_DETAIL_FAIL =
  'SYSTEM_OWNER_GET_USER_DETAIL_FAIL'
export const SYSTEM_OWNER_GET_USER_DETAIL_RESET =
  'SYSTEM_OWNER_GET_USER_DETAIL_RESET'

// update user details (system owner)
export const SYSTEM_OWNER_UPDATE_USER_DETAIL_REQUEST =
  'SYSTEM_OWNER_UPDATE_USER_DETAIL_REQUEST'
export const SYSTEM_OWNER_UPDATE_USER_DETAIL_SUCCESS =
  'SYSTEM_OWNER_UPDATE_USER_DETAIL_SUCCESS'
export const SYSTEM_OWNER_UPDATE_USER_DETAIL_FAIL =
  'SYSTEM_OWNER_UPDATE_USER_DETAIL_FAIL'
export const SYSTEM_OWNER_UPDATE_USER_DETAIL_RESET =
  'SYSTEM_OWNER_UPDATE_USER_DETAIL_RESET'

// register admin (system owner)
export const SYSTEM_OWNER_REGISTER_ADMIN_REQUEST =
  'SYSTEM_OWNER_REGISTER_ADMIN_REQUEST'
export const SYSTEM_OWNER_REGISTER_ADMIN_SUCCESS =
  'SYSTEM_OWNER_REGISTER_ADMIN_SUCCESS'
export const SYSTEM_OWNER_REGISTER_ADMIN_FAIL =
  'SYSTEM_OWNER_REGISTER_ADMIN_FAIL'
export const SYSTEM_OWNER_REGISTER_ADMIN_RESET =
  'SYSTEM_OWNER_REGISTER_ADMIN_RESET'
