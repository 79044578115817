import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form, Table } from 'react-bootstrap'
import ModalContainer from './ModalContainer'
import Loader from './Loader'
import Message from './Message'

import { getEmaTaskList } from '../actions/emaActions'
import { EMA_TASK_LIST_RESET } from '../constants/emaConstants'

// Present EmaTasks instead of EmaSchedule, as tasks are tied to participants and more updated

export const EmaSchedule = ({ email, studyId, participantId }) => {
  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const emaTaskList = useSelector((state) => state.emaTaskList)
  const {
    loading: loadingEmaTasks,
    error: errorEmaTasks,
    emaTasks: loadedEmaTasks,
    success: successEmaTasks,
  } = emaTaskList

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  useEffect(() => {}, [
    studyId,
    participantId,
    userInfo,
    logout,
    dispatch,
    successEmaTasks,
    loadingEmaTasks,
    loadedEmaTasks,
    errorEmaTasks,
  ])

  const handleOpen = () => {
    let now = new Date()
    if (userInfo && userInfo.expires > now && !logout) {
      if (
        studyId &&
        participantId &&
        !successEmaTasks &&
        !loadingEmaTasks &&
        !errorEmaTasks
      ) {
        dispatch(getEmaTaskList(studyId, participantId))
      }
    }
    setShow(true)
  }

  const handleClose = () => {
    dispatch({ type: EMA_TASK_LIST_RESET })
    setShow(false)
  }

  const headingStyle = {
    fontSize: '1.2rem',
  }

  return (
    <>
      <Button variant='primary' className='btn-sm m-1' onClick={handleOpen}>
        EMA Schedule
      </Button>

      <ModalContainer
        title={`Upcoming EMA Notifications`}
        show={show}
        handleClose={handleClose}
      >
        {errorEmaTasks && <Message variant='danger'>{errorEmaTasks}</Message>}

        {loadingEmaTasks ? (
          <Loader />
        ) : (
          <>
            {successEmaTasks && (
              <>
                <h2 style={headingStyle}>{`Participant: ${email}`}</h2>
                <Row>
                  {loadedEmaTasks && loadedEmaTasks.length > 0 ? (
                    <>
                      <Col md={12}>
                        <p>
                          Note: Reminders will be sent only when questionnaires
                          at that time are not completed
                        </p>
                        <Table
                          bordered
                          hover
                          responsive
                          className='table-sm'
                          style={{ marginTop: '20px' }}
                        >
                          <thead>
                            <tr>
                              <th>Notification Time</th>
                              <th>Timezone</th>
                              <th>Questionnaire(s)</th>
                              <th>Reminder?</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loadedEmaTasks &&
                              loadedEmaTasks.map((emaTask) => (
                                <tr key={emaTask.key}>
                                  <td>{emaTask.notification_datetime}</td>
                                  <td>{emaTask.timezone}</td>
                                  <td>{emaTask.qx_list.join(', ')}</td>
                                  <td>{emaTask.reminder ? 'Yes' : 'No'}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </>
                  ) : (
                    <Col>
                      <p>
                        There is no upcoming EMA task scheduled for this
                        participant.
                      </p>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </>
        )}
      </ModalContainer>
    </>
  )
}

export default EmaSchedule
