import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

//From admin module
import {
  systemOwnerDeleteUserReducer,
  systemOwnerGetUserDetailReducer,
  systemOwnerLoginReducer,
  systemOwnerUpdateUserDetailReducer,
  systemOwnerRegisterAdminReducer,
  userLoginReducer,
  labManagerGetLabUsersReducer,
  labManagerRegisterUserReducer,
} from './admin_module/reducers/userReducers'
import {
  systemOwnerCreateLabReducer,
  systemOwnerLabListReducer,
  systemOwnerLabUserListReducer,
  userLabNameListReducer,
  userGetLabInfoReducer,
} from './admin_module/reducers/labReducers'

//From media module
import {
  mediaFilesCreateReducer,
  mediaFilesDeleteReducer,
  mediaFilesGetReducer,
  mediaFilesReducer,
  mediaFileUpdateReducer,
  targetMediaFileReducer,
} from './media_module/reducers/mediaReducers'

//From study module
import {
  studyListReducer,
  studyCreateReducer,
  studyDeleteReducer,
  studyActivateReducer,
  studyDectivateReducer,
  studyEditBasicReducer,
  studyDetailsReducer,
  studyEditConsentReducer,
  stageAddReducer,
  stageDeleteReducer,
  stageEditBasicReducer,
  participantGroupAddReducer,
  participantGroupDeleteReducer,
  participantGroupEditReducer,
  studyEditExceptConsentReducer,
  studyAccessUsersReducer,
  studyAccessUpdateReducer,
} from './study_module/reducers/studyReducers'

//From qx module
import {
  mcCreateReducer,
  mcDeleteReducer,
  mcDetailsReducer,
  mcEditReducer,
  mcListReducer,
  qxCreateReducer,
  qxDeleteReducer,
  qxDetailsReducer,
  qxEditReducer,
  qxListReducer,
} from './qx_module/reducers/qxReducers'

//From participant module
import {
  assignPartiReducer,
  allPartiListReducer,
  partiListReducer,
  downloadTextRespReducer,
  downloadRespTimeReducer,
  createPartiReducer,
} from './participant_module/reducers/participantReducers'

//From ema module
import { emaTaskListReducer } from './ema_module/reducers/emaReducers'

//From access module
import { allAccessReducer } from './access_module/reducers/accessReducers'

//From diary ax module
import { diaryAxTaskListReducer } from './diary_ax_module/reducers/diaryAxReducers'

const reducer = combineReducers({
  // Start of admin module
  userLogin: userLoginReducer,
  labManagerGetLabUsers: labManagerGetLabUsersReducer,
  labManagerRegisterUser: labManagerRegisterUserReducer,
  labNameList: userLabNameListReducer,
  getLabInfo: userGetLabInfoReducer,

  // Start of system owner module
  systemOwnerLogin: systemOwnerLoginReducer,
  systemOwnerLabList: systemOwnerLabListReducer,
  systemOwnerLabUserList: systemOwnerLabUserListReducer,
  systemOwnerUserDelete: systemOwnerDeleteUserReducer,
  systemOwnerUserDetails: systemOwnerGetUserDetailReducer,
  systemOwnerUserUpdate: systemOwnerUpdateUserDetailReducer,
  systemOwnerLabCreate: systemOwnerCreateLabReducer,
  systemOwnerRegisterAdmin: systemOwnerRegisterAdminReducer,

  // Start of media module
  mediaFiles: mediaFilesReducer,
  createMediaFiles: mediaFilesCreateReducer,
  deleteMediaFiles: mediaFilesDeleteReducer,
  getMediaFiles: mediaFilesGetReducer,
  updateMediaFiles: mediaFileUpdateReducer,
  targetMediaFiles: targetMediaFileReducer,

  //Start of study module
  studyList: studyListReducer,
  studyCreate: studyCreateReducer,
  studyDelete: studyDeleteReducer,
  studyActivate: studyActivateReducer,
  studyDeactivate: studyDectivateReducer,
  studyEditBasic: studyEditBasicReducer,
  studyEditConsent: studyEditConsentReducer,
  stageAdd: stageAddReducer,
  stageDelete: stageDeleteReducer,
  stageEditBasic: stageEditBasicReducer,
  studyDetails: studyDetailsReducer,
  participantGroupAdd: participantGroupAddReducer,
  participantGroupDelete: participantGroupDeleteReducer,
  participantGroupEdit: participantGroupEditReducer,
  studyEditExceptConsent: studyEditExceptConsentReducer,
  studyAccessUsers: studyAccessUsersReducer,
  studyAccessUpdate: studyAccessUpdateReducer,

  //Start of qx module
  qxList: qxListReducer, // Start of qx
  qxCreate: qxCreateReducer,
  qxDelete: qxDeleteReducer,
  qxDetails: qxDetailsReducer,
  qxEdit: qxEditReducer,
  mcList: mcListReducer,
  mcCreate: mcCreateReducer,
  mcDelete: mcDeleteReducer,
  mcDetails: mcDetailsReducer,
  mcEdit: mcEditReducer,

  //Start of participant module
  allPartiList: allPartiListReducer,
  partiList: partiListReducer,
  assignParti: assignPartiReducer,
  downloadTextResp: downloadTextRespReducer,
  downloadRespTime: downloadRespTimeReducer,
  createParti: createPartiReducer,

  //Start of ema module
  emaTaskList: emaTaskListReducer,

  //Start of access module
  allAccess: allAccessReducer,

  //Start of diary ax module
  diaryAxTaskList: diaryAxTaskListReducer,
})

// Set the initialState from the localStorage if existed
const userInfoFromStorage = localStorage.getItem('adminModuleUserInfo')
  ? JSON.parse(localStorage.getItem('adminModuleUserInfo'))
  : null

// get system owner info from local storage
const systemOwnerInfoFromStorage = localStorage.getItem(
  'adminModuleSystemOwnerInfo'
)
  ? JSON.parse(localStorage.getItem('adminModuleSystemOwnerInfo'))
  : null

// Each key is the key of combineReducers defined above
const initialState = {
  userLogin: { userInfo: userInfoFromStorage, attemptLogin: false },
  systemOwnerLogin: {
    systemOwnerInfo: systemOwnerInfoFromStorage,
    attemptLogin: false,
  },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
