import {
  QX_CREATE_FAIL,
  QX_CREATE_REQUEST,
  QX_CREATE_RESET,
  QX_CREATE_SUCCESS,
  QX_DELETE_FAIL,
  QX_DELETE_REQUEST,
  QX_DELETE_RESET,
  QX_DELETE_SUCCESS,
  QX_DETAILS_FAIL,
  QX_DETAILS_REQUEST,
  QX_DETAILS_SUCCESS,
  QX_DETAILS_RESET,
  QX_LIST_FAIL,
  QX_LIST_REQUEST,
  QX_LIST_RESET,
  QX_LIST_SUCCESS,
  QX_EDIT_REQUEST,
  QX_EDIT_SUCCESS,
  QX_EDIT_FAIL,
  QX_EDIT_RESET,
  MC_LIST_REQUEST,
  MC_LIST_SUCCESS,
  MC_LIST_FAIL,
  MC_LIST_RESET,
  MC_CREATE_REQUEST,
  MC_CREATE_SUCCESS,
  MC_CREATE_FAIL,
  MC_CREATE_RESET,
  MC_DELETE_REQUEST,
  MC_DELETE_SUCCESS,
  MC_DELETE_FAIL,
  MC_DELETE_RESET,
  MC_DETAILS_REQUEST,
  MC_DETAILS_SUCCESS,
  MC_DETAILS_FAIL,
  MC_DETAILS_RESET,
  MC_EDIT_REQUEST,
  MC_EDIT_SUCCESS,
  MC_EDIT_FAIL,
  MC_EDIT_RESET,
} from '../constants/qxConstants'

export const qxListReducer = (state = { qx_list: [] }, action) => {
  switch (action.type) {
    case QX_LIST_REQUEST:
      return { loading: true, qx_list: [] }
    case QX_LIST_SUCCESS:
      return {
        loading: false,
        qx_list: action.payload.questionnaires,
        //pages: action.payload.pages,
        //page: action.payload.page,
        success: true,
      }
    case QX_LIST_FAIL:
      return { loading: false, error: action.payload }
    case QX_LIST_RESET:
      return { qx_list: [] }
    default:
      return state
  }
}

export const qxCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case QX_CREATE_REQUEST:
      return { loading: true }
    case QX_CREATE_SUCCESS:
      return { loading: false, success: true, qx: action.payload }
    case QX_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case QX_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const qxDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case QX_DELETE_REQUEST:
      return { loading: true }
    case QX_DELETE_SUCCESS:
      return { loading: false, success: true }
    case QX_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case QX_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const qxDetailsReducer = (
  state = { qx: { chin_content: [] } },
  action
) => {
  switch (action.type) {
    case QX_DETAILS_REQUEST:
      return { loading: true, ...state }
    case QX_DETAILS_SUCCESS:
      return { loading: false, success: true, qx: action.payload }
    case QX_DETAILS_FAIL:
      return { loading: false, qx: { chin_content: [] }, error: action.payload }
    case QX_DETAILS_RESET:
      return { qx: { chin_content: [] } }
    default:
      return state
  }
}

export const qxEditReducer = (state = {}, action) => {
  switch (action.type) {
    case QX_EDIT_REQUEST:
      return { loading: true }
    case QX_EDIT_SUCCESS:
      return { loading: false, success: true }
    case QX_EDIT_FAIL:
      return { loading: false, error: action.payload }
    case QX_EDIT_RESET:
      return {}
    default:
      return state
  }
}

export const mcListReducer = (state = { mcList: [] }, action) => {
  switch (action.type) {
    case MC_LIST_REQUEST:
      return { loading: true, mc_list: [] }
    case MC_LIST_SUCCESS:
      return {
        loading: false,
        mcList: action.payload.multipleChoices,
        success: true,
      }
    case MC_LIST_FAIL:
      return { loading: false, error: action.payload }
    case MC_LIST_RESET:
      return { mcList: [] }
    default:
      return state
  }
}

export const mcCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MC_CREATE_REQUEST:
      return { loading: true }
    case MC_CREATE_SUCCESS:
      return { loading: false, success: true, mc: action.payload }
    case MC_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case MC_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const mcDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MC_DELETE_REQUEST:
      return { loading: true }
    case MC_DELETE_SUCCESS:
      return { loading: false, success: true }
    case MC_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case MC_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const mcDetailsReducer = (state = { mc: {} }, action) => {
  switch (action.type) {
    case MC_DETAILS_REQUEST:
      return { loading: true, ...state }
    case MC_DETAILS_SUCCESS:
      return { loading: false, success: true, mc: action.payload }
    case MC_DETAILS_FAIL:
      return { loading: false, mc: {}, error: action.payload }
    case MC_DETAILS_RESET:
      return { mc: {} }
    default:
      return state
  }
}

export const mcEditReducer = (state = {}, action) => {
  switch (action.type) {
    case MC_EDIT_REQUEST:
      return { loading: true }
    case MC_EDIT_SUCCESS:
      return { loading: false, mc: action.payload, success: true }
    case MC_EDIT_FAIL:
      return { loading: false, error: action.payload }
    case MC_EDIT_RESET:
      return {}
    default:
      return state
  }
}
