import {
  SYSTEM_OWNER_DELETE_USER_FAIL,
  SYSTEM_OWNER_DELETE_USER_REQUEST,
  SYSTEM_OWNER_DELETE_USER_SUCCESS,
  SYSTEM_OWNER_LOGIN_FAIL,
  SYSTEM_OWNER_LOGIN_REQUEST,
  SYSTEM_OWNER_LOGIN_SUCCESS,
  SYSTEM_OWNER_LOGOUT,
  SYSTEM_OWNER_GET_USER_DETAIL_FAIL,
  SYSTEM_OWNER_GET_USER_DETAIL_REQUEST,
  SYSTEM_OWNER_GET_USER_DETAIL_SUCCESS,
  SYSTEM_OWNER_UPDATE_USER_DETAIL_FAIL,
  SYSTEM_OWNER_UPDATE_USER_DETAIL_REQUEST,
  SYSTEM_OWNER_UPDATE_USER_DETAIL_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  SYSTEM_OWNER_UPDATE_USER_DETAIL_RESET,
  SYSTEM_OWNER_GET_USER_DETAIL_RESET,
  SYSTEM_OWNER_REGISTER_ADMIN_REQUEST,
  SYSTEM_OWNER_REGISTER_ADMIN_SUCCESS,
  SYSTEM_OWNER_REGISTER_ADMIN_FAIL,
  SYSTEM_OWNER_REGISTER_ADMIN_RESET,
  LAB_MANAGER_REGISTER_USER_REQUEST,
  LAB_MANAGER_REGISTER_USER_SUCCESS,
  LAB_MANAGER_REGISTER_USER_FAIL,
  LAB_MANAGER_REGISTER_USER_RESET,
  LAB_MANAGER_GET_LABS_USERS_REQUEST,
  LAB_MANAGER_GET_LABS_USERS_SUCCESS,
  LAB_MANAGER_GET_LABS_USERS_FAIL,
  LAB_MANAGER_GET_LABS_USERS_RESET,
} from '../constants/userConstants'

/********************* reducers for users ******************/

// login in (user)
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true, attemptLogin: true }

    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        attemptLogin: true,
        userInfo: action.payload,
        logout: false,
      }

    case USER_LOGIN_FAIL:
      return { loading: false, attemptLogin: true, error: action.payload }

    case USER_LOGOUT:
      return { attemptLogin: false, logout: true } //The state stores the login user info

    default:
      return state
  }
}

// list users according to labs (system owner)
export const labManagerGetLabUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case LAB_MANAGER_GET_LABS_USERS_REQUEST:
      return { loading: true, labUsers: [] }

    case LAB_MANAGER_GET_LABS_USERS_SUCCESS:
      return { loading: false, success: true, labUsers: action.payload }

    case LAB_MANAGER_GET_LABS_USERS_FAIL:
      return { loading: false, success: false, error: action.payload }

    case LAB_MANAGER_GET_LABS_USERS_RESET:
      return {}

    default:
      return state
  }
}

export const labManagerRegisterUserReducer = (state = {}, action) => {
  switch (action.type) {
    case LAB_MANAGER_REGISTER_USER_REQUEST:
      return { loading: true }

    case LAB_MANAGER_REGISTER_USER_SUCCESS:
      return { loading: false, registerResp: action.payload }

    case LAB_MANAGER_REGISTER_USER_FAIL:
      return { loading: false, error: action.payload }

    case LAB_MANAGER_REGISTER_USER_RESET:
      return { loading: false, registerResp: undefined, error: undefined }

    default:
      return state
  }
}

/********************** reducers for system owner ***************/

// log in (system owner)
export const systemOwnerLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case SYSTEM_OWNER_LOGIN_REQUEST:
      return { loading: true, attemptLogin: true }

    case SYSTEM_OWNER_LOGIN_SUCCESS:
      return {
        loading: false,
        attemptLogin: true,
        systemOwnerInfo: action.payload,
        logout: false,
      }

    case SYSTEM_OWNER_LOGIN_FAIL:
      return { loading: false, attemptLogin: true, error: action.payload }

    case SYSTEM_OWNER_LOGOUT:
      return { attemptLogin: false, logout: true }

    default:
      return state
  }
}

// delete user (system owner)
export const systemOwnerDeleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case SYSTEM_OWNER_DELETE_USER_REQUEST:
      return { loading: true }

    case SYSTEM_OWNER_DELETE_USER_SUCCESS:
      return { loading: false, success: true }

    case SYSTEM_OWNER_DELETE_USER_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

// get user detail (system owner)
export const systemOwnerGetUserDetailReducer = (
  state = { user: {} },
  action
) => {
  switch (action.type) {
    case SYSTEM_OWNER_GET_USER_DETAIL_REQUEST:
      return { ...state, loading: true }

    case SYSTEM_OWNER_GET_USER_DETAIL_SUCCESS:
      return { loading: false, user: action.payload }

    case SYSTEM_OWNER_GET_USER_DETAIL_FAIL:
      return { loading: false, error: action.payload }

    case SYSTEM_OWNER_GET_USER_DETAIL_RESET:
      return { user: {} }

    default:
      return state
  }
}

// update user detail (system owner)
export const systemOwnerUpdateUserDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case SYSTEM_OWNER_UPDATE_USER_DETAIL_REQUEST:
      return { loading: true }

    case SYSTEM_OWNER_UPDATE_USER_DETAIL_SUCCESS:
      return { loading: false, success: true }

    case SYSTEM_OWNER_UPDATE_USER_DETAIL_FAIL:
      return { loading: false, error: action.payload }

    case SYSTEM_OWNER_UPDATE_USER_DETAIL_RESET:
      return {
        user: {},
      }

    default:
      return state
  }
}

export const systemOwnerRegisterAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case SYSTEM_OWNER_REGISTER_ADMIN_REQUEST:
      return { loading: true }

    case SYSTEM_OWNER_REGISTER_ADMIN_SUCCESS:
      return { loading: false, registerResp: action.payload }

    case SYSTEM_OWNER_REGISTER_ADMIN_FAIL:
      return { loading: false, error: action.payload }

    case SYSTEM_OWNER_REGISTER_ADMIN_RESET:
      return { loading: false, registerResp: undefined, error: undefined }

    default:
      return state
  }
}
