const getDefaultEmaAx = () => {
  return {
    questionnaire_list: [],
    time_randomize: false,
    time_list: [], //use when time_randomize = true. time in HH:mm, 24-hour format. In timezone, not in unix time
    randomize_settings: [], //use when time_randomize = false. time in HH:mm, 24-hour format. In timezone, not in unix time
    timezone: 'Hongkong',
    day_one_eq_same_day: true,
    reminder_interval: 5, //in minutes
    response_in: 15, //in minutes
  }
}

export default getDefaultEmaAx
