import axios from 'axios'
import { USER_LOGOUT } from '../../admin_module/constants/userConstants'
import {
  MC_CREATE_FAIL,
  MC_CREATE_REQUEST,
  MC_CREATE_SUCCESS,
  MC_DELETE_FAIL,
  MC_DELETE_REQUEST,
  MC_DELETE_SUCCESS,
  MC_DETAILS_FAIL,
  MC_DETAILS_REQUEST,
  MC_DETAILS_SUCCESS,
  MC_EDIT_FAIL,
  MC_EDIT_REQUEST,
  MC_EDIT_SUCCESS,
  MC_LIST_FAIL,
  MC_LIST_REQUEST,
  MC_LIST_SUCCESS,
  QX_CREATE_FAIL,
  QX_CREATE_REQUEST,
  QX_CREATE_SUCCESS,
  QX_DELETE_FAIL,
  QX_DELETE_REQUEST,
  QX_DELETE_SUCCESS,
  QX_DETAILS_FAIL,
  QX_DETAILS_REQUEST,
  QX_DETAILS_SUCCESS,
  QX_EDIT_FAIL,
  QX_EDIT_REQUEST,
  QX_EDIT_SUCCESS,
  QX_LIST_FAIL,
  QX_LIST_REQUEST,
  QX_LIST_SUCCESS,
} from '../constants/qxConstants'

import BACKEND_URL from '../../backendUrl'

const standardErrorHandling = (dispatch, error, errorConstant) => {
  if (error.response && error.response.status === 401) {
    // This ensure userInfo is clear
    dispatch({
      type: USER_LOGOUT,
    })
    localStorage.removeItem('adminModuleUserInfo')
  } else {
    // In the custom express error handler, there could be custom error message,
    //  which is stored in error.response as { message: ..., stack: ... }
    // If there is no such custom error message, output the generic error message (i.e. error.message)
    dispatch({
      type: errorConstant,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Following are the actions that will be fired off after calling by components
export const getQxList =
  (pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: QX_LIST_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        BACKEND_URL + `/api/qx_list?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: QX_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, QX_LIST_FAIL)
    }
  }

export const createQx = (initialData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QX_CREATE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      BACKEND_URL + `/api/qx_list`,
      initialData,
      config
    )

    dispatch({
      type: QX_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, QX_CREATE_FAIL)
  }
}

export const deleteQx = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QX_DELETE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(BACKEND_URL + `/api/qx_list/${id}`, config)

    dispatch({
      type: QX_DELETE_SUCCESS,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, QX_DELETE_FAIL)
  }
}

export const editQx = (id, updatedQx) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QX_EDIT_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      BACKEND_URL + `/api/qx_list/${id}/edit`,
      updatedQx,
      config
    )

    dispatch({
      type: QX_EDIT_SUCCESS,
    })

    //Trigger relaod of questionnaire details
    dispatch({
      type: QX_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, QX_EDIT_FAIL)
  }
}

// If something is needed to pass into an action (e.g. id), specifies it as function argument
export const listQxDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: QX_DETAILS_REQUEST })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(BACKEND_URL + `/api/qx_list/${id}`, config)

    dispatch({
      type: QX_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, QX_DETAILS_FAIL)
  }
}

// If something is needed to pass into an action (e.g. id), specifies it as function argument
export const listMc = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MC_LIST_REQUEST })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/qx_list/${id}/mc`,
      config
    )

    dispatch({
      type: MC_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, MC_LIST_FAIL)
  }
}

export const createMc = (qId, initialData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MC_CREATE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      BACKEND_URL + `/api/qx_list/${qId}/mc`,
      initialData,
      config
    )

    dispatch({
      type: MC_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, MC_CREATE_FAIL)
  }
}

export const deleteMc = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MC_DELETE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(BACKEND_URL + `/api/qx_list/mc/${id}`, config)

    dispatch({
      type: MC_DELETE_SUCCESS,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, MC_DELETE_FAIL)
  }
}

export const editMc = (updatedMc) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MC_EDIT_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      BACKEND_URL + `/api/qx_list/mc/${updatedMc._id}/edit`,
      updatedMc,
      config
    )

    dispatch({
      type: MC_EDIT_SUCCESS,
    })

    dispatch({
      type: MC_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, MC_EDIT_FAIL)
  }
}

// If something is needed to pass into an action (e.g. id), specifies it as function argument
export const listMcDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MC_DETAILS_REQUEST })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/qx_list/mc/${id}`,
      config
    )

    dispatch({
      type: MC_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, MC_DETAILS_FAIL)
  }
}
