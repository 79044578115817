import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Table, Card, Form } from 'react-bootstrap'
import Message from '../components/Message'
import ToastMessage from '../components/ToastMessage'
import Loader from '../components/Loader'
import S3ZipperDownload from '../../media_module/components/S3ZipperDownload'
import AssignParticipant from '../components/AssignParticipant'
import EmaSchedule from '../../ema_module/components/EmaSchedule'
import DiaryAxTask from '../../diary_ax_module/components/DiaryAxTask'
import DownloadTextResponse from '../components/DownloadTextResponse'

import ParticipantFilters from '../components/ParticipantFilters'
import CreateParticipant from '../components/CreateParticipant'

import { getPartiList } from '../actions/participantActions'
import { PARTI_LIST_RESET } from '../constants/participantConstants'

import getDateTimeString from '../utils/getDateTimeString'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'
import { getAllAccess } from '../../access_module/actions/accessActions'
import { GET_ALL_ACCESS_RESET } from '../../access_module/constants/accessConstants'

const ParticipantListScreen = ({ history, match }) => {
  const studyId = match.params.studyId

  // For toast message
  const [toastMessages, setToastMessages] = useState([])

  // For participant filters
  const [currentStage, setCurrentStage] = useState('')
  const [currentGroup, setCurrentGroup] = useState('')
  const [studyCode, setStudyCode] = useState('')
  const [studyName, setStudyName] = useState('')

  // For download images
  const [errorDownloadImgResp, setErrorDownloadImgResp] = useState([])

  // For reload one participant after group assignment
  const [participantToBeReloaded, setParticipantToBeReloaded] = useState(null)

  const toastOnCloseHandler = async (uid) => {
    setToastMessages((toastMessages) =>
      toastMessages.filter((message) => message.uid !== uid)
    )
  }

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const partiList = useSelector((state) => state.partiList)
  const {
    participantsData,
    error: errorGetList,
    loading: loadingGetList,
    success: successGetList,
    reloadTargetParticipantIfSpecified: reloadTargetParticipant,
  } = partiList

  const allAccess = useSelector((state) => state.allAccess)
  const {
    loading: laodingGetAllAccess,
    error: errorGetAllAccess,
    success: successGetAllAccess,
    allAccessList,
  } = allAccess

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      dispatch({ type: PARTI_LIST_RESET })
      history.push('/login')
    } else {
      if (
        !loadingGetList &&
        !errorGetList &&
        !successGetList &&
        currentStage.length !== 0 &&
        currentGroup.length !== 0
      ) {
        // Put the reset for getting all access just before loading the main content again
        dispatch({ type: GET_ALL_ACCESS_RESET })
        if (reloadTargetParticipant && participantToBeReloaded) {
          dispatch(
            getPartiList(
              studyId,
              currentStage,
              currentGroup,
              participantToBeReloaded
            )
          )
        } else {
          dispatch(getPartiList(studyId, currentStage, currentGroup))
        }
      }
      if (!laodingGetAllAccess && !successGetAllAccess && !errorGetAllAccess) {
        dispatch(getAllAccess())
      }
    }
  }, [
    dispatch,
    history,
    userInfo,
    logout,
    loadingGetList,
    errorGetList,
    successGetList,
    studyId,
    participantsData,
    currentStage,
    currentGroup,
    errorGetAllAccess,
    laodingGetAllAccess,
    successGetAllAccess,
    partiList,
    reloadTargetParticipant,
    participantToBeReloaded,
  ])

  const getStageStartTime = (participation) => {
    const enrolmentTime =
      participation.enrolment_time[participation.enrolment_time.length - 1]
    return getDateTimeString(enrolmentTime.start_datetime)
  }

  //selectedGroupId could be equal to 'all', which represents all groups
  const handleFiltersUpdated = (selectedStageId, selectedGroupId) => {
    dispatch({ type: PARTI_LIST_RESET })
    setCurrentStage(selectedStageId)
    setCurrentGroup(selectedGroupId)
  }

  const onStudyLoaded = (study) => {
    setStudyCode(study.study_code)
    setStudyName(study.chin_name)
  }

  const onAssignParticipantSuccess = (participantId) => {
    setParticipantToBeReloaded(participantId)
    setToastMessages((toastMessages) => [
      ...toastMessages,
      {
        uid: new Date().valueOf(),
        variant: 'info',
        message: 'Participant has been assigned to the designated stage',
      },
    ])
  }

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  return (
    <>
      {/* Display toast messages */}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          height: '80vh',
          width: '80vw',
          zIndex: 1,
        }}
      >
        {toastMessages.map((toastMessage) => (
          <ToastMessage
            key={toastMessage.uid}
            variant={toastMessage.variant}
            onClose={() => toastOnCloseHandler(toastMessage.uid)}
          >
            {toastMessage.message}
          </ToastMessage>
        ))}
      </div>

      <Row className='align-items-center'>
        <Col>
          <h1 style={headingStyle}>Participant List</h1>
        </Col>

        {studyName && studyCode && (
          <Col className='text-right'>
            <h5>
              Study: {studyName} ({studyCode})
            </h5>
          </Col>
        )}
      </Row>

      {allAccessList.find(
        (a) =>
          a.area === 'PARTICIPANT' &&
          a.modifier === 'CREATE' &&
          a.task === 'NEW_PARTICIPANT'
      ) && (
        <Row>
          <Col className='text-right'>
            <CreateParticipant studyId={studyId}></CreateParticipant>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Card className='p-2 my-2 rounded'>
            <ParticipantFilters
              studyId={studyId}
              onFilterUpdated={handleFiltersUpdated}
              onStudyLoaded={onStudyLoaded}
            />
            {successGetList && (
              <Row>
                <Col>
                  <DownloadTextResponse
                    studyId={studyId}
                    selectedStage={currentStage}
                    selectedGroup={currentGroup}
                  ></DownloadTextResponse>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>

      {errorGetList && <Message variant='danger'>{errorGetList}</Message>}
      {loadingGetList ? (
        <Loader />
      ) : (
        !logout &&
        participantsData &&
        participantsData.participants && (
          <>
            {participantsData.participants.length > 0 ? (
              <Table
                striped
                bordered
                hover
                responsive
                className='table-sm'
                style={{ marginTop: '20px' }}
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>EMAIL</th>
                    <th>ENROLMENT STATUS</th>
                    <th>ASSIGNED STAGE (#)</th>
                    <th>ASSIGNED GROUP (#)</th>
                    <th>LOAD Ax AFTER STAGE ASSIGNED?</th>
                    <th>TIME FIRST LOADING Ax AFTER STAGE ASSIGNED</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Particpants here are with participation filtered */}
                  {participantsData.participants.map((participant) => (
                    <tr key={participant._id}>
                      <td className='align-middle'>
                        {participant.participant_code}
                      </td>
                      <td className='align-middle'>{participant.email}</td>
                      <td className='align-middle'>
                        {participant.participation[0].status}
                      </td>
                      <td className='align-middle'>
                        {participant.participation[0].current_stage.chin_name} (
                        {participant.participation[0].current_stage.stage_num})
                      </td>
                      <td className='align-middle'>
                        {participant.participation[0].current_group.name} (
                        {participant.participation[0].current_group.group_num})
                      </td>
                      <td className='align-middle'>
                        {participant.participation[0].arrive_assigned
                          ? 'Yes'
                          : 'No'}
                      </td>
                      <td className='align-middle'>
                        {participant.participation[0].arrive_assigned
                          ? getStageStartTime(participant.participation[0])
                          : ''}
                      </td>
                      <td className='align-middle'>
                        {successGetList && (
                          <AssignParticipant
                            participantId={participant._id}
                            enrolment={participant.participation[0]}
                            email={participant.email}
                            onSaveSuccess={onAssignParticipantSuccess}
                          ></AssignParticipant>
                        )}

                        {successGetList && (
                          <EmaSchedule
                            email={participant.email}
                            studyId={participant.participation[0].study}
                            participantId={participant._id}
                          ></EmaSchedule>
                        )}

                        {successGetList &&
                          participant.participation[0].diary_ax_tasks.length >
                            0 && (
                            <DiaryAxTask
                              email={participant.email}
                              studyId={participant.participation[0].study}
                              participantId={participant._id}
                            ></DiaryAxTask>
                          )}

                        <S3ZipperDownload
                          participantId={participant._id}
                          studyId={studyId}
                          onDownloadError={(error) =>
                            setErrorDownloadImgResp(error)
                          }
                        ></S3ZipperDownload>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Row className='align-items-center my-3'>
                <Col>
                  <h4>No participant is found</h4>
                </Col>
              </Row>
            )}
          </>
        )
      )}
    </>
  )
}

export default ParticipantListScreen
