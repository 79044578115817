import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button, Row, Col } from 'react-bootstrap'

import { deleteGroup } from '../actions/studyActions'

import EmaAx from '../../ema_module/components/EmaAx'
import getDefaultEmaAx from '../../ema_module/utils/getDefaultEmaAx'
import resetErrorState from '../utils/resetErrorState'
import QuestionnaireListInfo from './QuestionnaireListInfo'
import EMA_OR_EMI from '../../ema_or_emi'

import DiaryAx from '../../diary_ax_module/components/DiaryAx'

export const StudyGroup = ({ group, qxList }) => {
  const allAccess = useSelector((state) => state.allAccess)
  const { allAccessList } = allAccess

  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [groupNum, setGroupNum] = useState('')
  const [diaryList, setDiaryList] = useState([])
  const [emaList, setEmaList] = useState([])
  const [qxAxList, setQxAxList] = useState([])
  const [noSubmissionLimitList, setNoSubmissionLimitList] = useState([])
  const [loadedQxList, setLoadedQxList] = useState([])

  //For later versions, add diary and questionnaire

  useEffect(() => {
    setName(group.name)
    setGroupNum(group.group_num)
    setEmaList(group.ema_list)
    setDiaryList(group.diary_list)
    setQxAxList(group.questionnaire_list)
    setNoSubmissionLimitList(group.no_submission_limit_list)
    setLoadedQxList(qxList)
  }, [group, qxList])

  const deleteGroupHandler = () => {
    if (
      window.confirm(
        'This operation will remove unsaved changes. Please save changes first if you have not done that. Are you sure you want to go ahead?'
      )
    ) {
      resetErrorState(dispatch)
      dispatch(deleteGroup(group.stage, groupNum))
    }
  }

  const addDiaryAxSettings = () => {
    group.diary_list = [
      ...group.diary_list,
      {
        questionnaire_list: [],
        fix_start_date: false,
        start_date: undefined,
        number_of_days: undefined,
        timezone: 'Hongkong',
      },
    ]
    setDiaryList(group.diary_list)
  }

  const deleteDiaryAxSettings = (index) => {
    if (window.confirm('Confirm?')) {
      let temp = []
      for (let i = 0; i < group.diary_list.length; ++i) {
        if (i === index) {
          continue
        } else {
          temp.push(group.diary_list[i])
        }
      }
      group.diary_list = temp
      setDiaryList(temp)
    }
  }

  const addEMASettings = () => {
    group.ema_list = [...group.ema_list, getDefaultEmaAx()]
    setEmaList(group.ema_list)
  }

  const deleteEMASettings = (index) => {
    if (window.confirm('Confirm?')) {
      let temp = []
      for (let i = 0; i < group.ema_list.length; ++i) {
        if (i === index) {
          continue
        } else {
          temp.push(group.ema_list[i])
        }
      }
      group.ema_list = temp
      setEmaList(temp)
    }
  }

  const headingStyle = {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginBottom: '0.8rem',
  }

  const updateSingleQxList = async (e, type) => {
    let tempList

    if (type === 'qxAx') {
      tempList = JSON.parse(JSON.stringify(qxAxList))
    } else {
      //No submission limit
      tempList = JSON.parse(JSON.stringify(noSubmissionLimitList))
    }
    let removeOption = false
    for (let i = 0; i < tempList.length; ++i) {
      if (tempList[i] === e.target.value) {
        tempList.splice(i, 1)
        removeOption = true
      }
    }
    if (removeOption === false) {
      tempList.push(e.target.value)
    }
    if (type === 'qxAx') {
      group.questionnaire_list = tempList
      setQxAxList(tempList)
    } else {
      group.no_submission_limit_list = tempList
      setNoSubmissionLimitList(tempList)
    }
  }

  return (
    <>
      <hr />
      <div>
        <h2 style={headingStyle}>Participant Group {group.group_num}</h2>
        {group.group_num > 1 &&
          allAccessList.find(
            (a) =>
              a.area === 'STUDY' &&
              a.modifier === 'DELETE' &&
              a.task === 'STUDY_STAGE'
          ) && (
            <Button
              variant='danger'
              style={{ float: 'right' }}
              onClick={deleteGroupHandler}
            >
              Delete Group
            </Button>
          )}
      </div>

      <Form>
        <Form.Group controlId={'groupName_' + group._id}>
          <Form.Label style={{ whiteSpace: 'normal' }}>Group Name</Form.Label>
          <Form.Control
            type='text'
            value={name}
            onChange={(e) => {
              group.name = e.target.value
              setName(e.target.value)
            }}
          ></Form.Control>
        </Form.Group>

        {loadedQxList && (
          <>
            {allAccessList.find(
              (a) =>
                a.area === 'STUDY' &&
                a.modifier === 'EDIT' &&
                a.task === 'STUDY_EMA_ASSESSMENTS'
            ) && (
              <div style={{ marginBottom: '18px' }}>
                <Form.Label
                  style={{ whiteSpace: 'normal', marginBottom: '0px' }}
                >
                  EMA Assessments
                </Form.Label>

                {emaList.map((emaAx, index) => (
                  <EmaAx
                    key={'ema_settings' + group._id + index}
                    group={group}
                    index={index}
                    qx_list={loadedQxList}
                    handleDelete={() => {
                      deleteEMASettings(index)
                    }}
                  ></EmaAx>
                ))}

                <Row>
                  <Col md={12}>
                    <Button
                      style={{ width: '100%', marginTop: '5px' }}
                      variant='primary'
                      onClick={addEMASettings}
                    >
                      Add EMA Settings
                    </Button>
                  </Col>
                </Row>
              </div>
            )}

            <Form.Group controlId={'groupQx_' + group._id}>
              {EMA_OR_EMI === 'ema' ? (
                <>
                  <Form.Label style={{ whiteSpace: 'normal' }}>
                    Assessments that require one-time response at this stage
                    (Click to select/unselect)
                  </Form.Label>
                  <Form.Control
                    as='select'
                    value={qxAxList}
                    onChange={() => {}}
                    onClick={async (e) => {
                      await updateSingleQxList(e, 'qxAx')
                    }}
                    multiple
                  >
                    {loadedQxList.map((qx) => (
                      <option key={qx._id + group._id} value={qx._id}>
                        {qx.chin_name} ({qx.abbrev})
                      </option>
                    ))}
                  </Form.Control>
                  <Row>
                    <Col md={12}>
                      <QuestionnaireListInfo
                        group={group}
                        qxList={qxAxList.map((qxId) =>
                          loadedQxList.find((qx) => qx._id === qxId)
                        )}
                      ></QuestionnaireListInfo>
                    </Col>
                  </Row>
                </>
              ) : (
                qxAxList &&
                qxAxList.length > 0 && (
                  <>
                    <Form.Label style={{ whiteSpace: 'normal' }}>
                      Assessments specified in treatment materials that require
                      one-time response at this stage
                    </Form.Label>
                    <ul>
                      {loadedQxList
                        .filter((qx) =>
                          qxAxList.find((x) => String(x) === String(qx._id))
                        )
                        .map((qx) => (
                          <li>
                            {qx.chin_name} ({qx.abbrev})
                          </li>
                        ))}
                    </ul>
                  </>
                )
              )}
            </Form.Group>

            <Form.Group controlId={'groupUnlimited_' + group._id}>
              {EMA_OR_EMI === 'ema' ? (
                <>
                  <Form.Label style={{ whiteSpace: 'normal' }}>
                    Assessments that allow anytime and unlimited responses
                    (Click to select/unselect)
                  </Form.Label>
                  <Form.Control
                    as='select'
                    value={noSubmissionLimitList}
                    onChange={() => {}}
                    onClick={async (e) => {
                      await updateSingleQxList(e, 'noSubmissionLimit')
                    }}
                    multiple
                  >
                    {loadedQxList.map((qx) => (
                      <option key={qx._id + group._id} value={qx._id}>
                        {qx.chin_name} ({qx.abbrev})
                      </option>
                    ))}
                  </Form.Control>
                </>
              ) : (
                noSubmissionLimitList &&
                noSubmissionLimitList.length > 0 && (
                  <>
                    <Form.Label style={{ whiteSpace: 'normal' }}>
                      Assessments specified in treatment materials that allow
                      anytime and unlimited responses
                    </Form.Label>
                    <ul>
                      {loadedQxList
                        .filter((qx) =>
                          noSubmissionLimitList.find(
                            (x) => String(x) === String(qx._id)
                          )
                        )
                        .map((qx) => (
                          <li>
                            {qx.chin_name} ({qx.abbrev})
                          </li>
                        ))}
                    </ul>
                  </>
                )
              )}
            </Form.Group>

            {allAccessList.find(
              (a) =>
                a.area === 'STUDY' &&
                a.modifier === 'EDIT' &&
                a.task === 'STUDY_DIARY_ASSESSMENTS'
            ) && (
              <div style={{ marginBottom: '18px' }}>
                <Form.Label
                  style={{ whiteSpace: 'normal', marginBottom: '0px' }}
                >
                  Diary Assessments
                </Form.Label>

                {diaryList.map((diaryAx, index) => (
                  <DiaryAx
                    key={'diary_ax_settings' + group._id + index}
                    group={group}
                    index={index}
                    qx_list={loadedQxList}
                    handleDelete={() => {
                      deleteDiaryAxSettings(index)
                    }}
                  ></DiaryAx>
                ))}

                <Row>
                  <Col md={12}>
                    <Button
                      style={{ width: '100%', marginTop: '5px' }}
                      variant='primary'
                      onClick={addDiaryAxSettings}
                    >
                      Add Diary Assessment Settings
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </Form>
    </>
  )
}

export default StudyGroup
