import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button } from 'react-bootstrap'

import StudyGroup from './StudyGroup'
import { deleteStage, addGroup } from '../actions/studyActions'
import resetErrorState from '../utils/resetErrorState'

export const StudyStage = ({ studyId, stage, qxList }) => {
  const allAccess = useSelector((state) => state.allAccess)
  const { allAccessList } = allAccess

  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [stageNum, setStageNum] = useState('')
  const [progressMessage, setProgressMessage] = useState('')
  const [completeMessage, setCompleteMessage] = useState('')
  const [participantGroupList, setParticipantGroupList] = useState([])
  const [loadedQxList, setLoadedQxList] = useState([])

  useEffect(() => {
    setName(stage.chin_name)
    setStageNum(stage.stage_num)
    setProgressMessage(stage.chin_progress_message)
    setCompleteMessage(stage.chin_complete_message)
    setParticipantGroupList(stage.participant_group_list)
    setLoadedQxList(qxList)
  }, [stage, qxList])

  const deleteStageHandler = () => {
    if (
      window.confirm(
        'This operation will remove unsaved changes. Please save changes first if you have not done that. Are you sure you want to go ahead?'
      )
    ) {
      resetErrorState(dispatch)
      dispatch(deleteStage(studyId, stageNum))
    }
  }

  const addGroupHandler = () => {
    if (
      window.confirm(
        'This operation will remove unsaved changes. Please save changes first if you have not done that. Are you sure you want to go ahead?'
      )
    ) {
      resetErrorState(dispatch)
      dispatch(addGroup(stage._id))
    }
  }

  const headingStyle = {
    float: 'left',
    fontSize: '1.6rem',
    fontWeight: 'bold',
  }

  return (
    <>
      <div>
        <h1 style={headingStyle}>Stage {stageNum}</h1>
        {stageNum > 1 &&
          allAccessList.find(
            (a) =>
              a.area === 'STUDY' &&
              a.modifier === 'DELETE' &&
              a.task === 'STUDY_STAGE'
          ) && (
            <Button
              variant='danger'
              style={{ float: 'right' }}
              onClick={deleteStageHandler}
            >
              Delete Stage
            </Button>
          )}
      </div>

      <hr />

      <Form>
        <Form.Group controlId='stage_name'>
          <Form.Label style={{ whiteSpace: 'normal' }}>
            Stage Name (which will be presented to participants)
          </Form.Label>
          <Form.Control
            type='text'
            value={name}
            onChange={(e) => {
              stage.chin_name = e.target.value
              setName(e.target.value)
            }}
          ></Form.Control>
        </Form.Group>

        {allAccessList.find(
          (a) =>
            a.area === 'STUDY' &&
            a.modifier === 'EDIT' &&
            a.task === 'STUDY_STAGE_MESSAGES'
        ) && (
          <>
            <Form.Group controlId='stage_progressMessage'>
              <Form.Label style={{ whiteSpace: 'normal' }}>
                Message displayed during this stage
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={progressMessage}
                onChange={(e) => {
                  stage.chin_progress_message = e.target.value
                  setProgressMessage(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='stage_completeMessage'>
              <Form.Label style={{ whiteSpace: 'normal' }}>
                Message displayed at the time when this stage is completed
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={completeMessage}
                onChange={(e) => {
                  stage.chin_complete_message = e.target.value
                  setCompleteMessage(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>
          </>
        )}
      </Form>

      {participantGroupList.map((group) => (
        <StudyGroup
          group={group}
          key={group.group_num}
          qxList={loadedQxList}
        ></StudyGroup>
      ))}

      {stageNum !== 1 &&
        allAccessList.find(
          (a) =>
            a.area === 'STUDY' &&
            a.modifier === 'ADD' &&
            a.task === 'PARTICIPANT_GROUP'
        ) && (
          <>
            <hr />
            <Button variant='primary' onClick={addGroupHandler}>
              Add Participant Group
            </Button>
          </>
        )}
    </>
  )
}

export default StudyStage
