import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Table } from 'react-bootstrap'
import ModalContainer from './ModalContainer'
import Loader from './Loader'
import Message from './Message'

import { getDiaryAxTaskList } from '../actions/diaryAxActions'
import { DIARY_AX_TASK_LIST_RESET } from '../constants/diaryAxConstants'

// Present EmaTasks instead of EmaSchedule, as tasks are tied to participants and more updated

export const DiaryAxTask = ({ email, studyId, participantId }) => {
  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const diaryAxTaskList = useSelector((state) => state.diaryAxTaskList)
  const {
    loading: loadingDiaryAxTasks,
    error: errorDiaryAxTasks,
    diaryAxTasks: loadedDiaryAxTasks,
    success: successDiaryAxTasks,
  } = diaryAxTaskList

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  useEffect(() => {}, [
    studyId,
    participantId,
    userInfo,
    logout,
    dispatch,
    successDiaryAxTasks,
    loadingDiaryAxTasks,
    loadedDiaryAxTasks,
    errorDiaryAxTasks,
  ])

  const handleOpen = () => {
    let now = new Date()
    if (userInfo && userInfo.expires > now && !logout) {
      if (
        studyId &&
        participantId &&
        !successDiaryAxTasks &&
        !loadingDiaryAxTasks &&
        !errorDiaryAxTasks
      ) {
        dispatch(getDiaryAxTaskList(studyId, participantId))
      }
    }
    setShow(true)
  }

  const handleClose = () => {
    dispatch({ type: DIARY_AX_TASK_LIST_RESET })
    setShow(false)
  }

  const headingStyle = {
    fontSize: '1.2rem',
  }

  return (
    <>
      <Button variant='primary' className='btn-sm m-1' onClick={handleOpen}>
        Assigned Diary Assessments
      </Button>

      <ModalContainer
        title={`Assigned Diary Assessments`}
        show={show}
        handleClose={handleClose}
      >
        {errorDiaryAxTasks && (
          <Message variant='danger'>{errorDiaryAxTasks}</Message>
        )}

        {loadingDiaryAxTasks ? (
          <Loader />
        ) : (
          <>
            {successDiaryAxTasks && (
              <>
                <h2 style={headingStyle}>{`Participant: ${email}`}</h2>
                <Row>
                  {loadedDiaryAxTasks && loadedDiaryAxTasks.length > 0 ? (
                    <>
                      <Col md={12}>
                        <p>
                          Note: The following start date defines the first day
                          the participant can submit questionnaire response.
                        </p>
                        <Table
                          bordered
                          hover
                          responsive
                          className='table-sm'
                          style={{ marginTop: '20px' }}
                        >
                          <thead>
                            <tr>
                              <th>Start Date</th>
                              <th>Questionnaire</th>
                              <th>Number of Days Required</th>
                              <th>Number of Days Left</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loadedDiaryAxTasks &&
                              loadedDiaryAxTasks.map((diaryAxTask) => (
                                <tr key={diaryAxTask._id}>
                                  <td>{diaryAxTask.diary_task_start_date}</td>
                                  <td>{diaryAxTask.questionnaire.abbrev}</td>
                                  <td>{diaryAxTask.number_of_days_required}</td>
                                  <td>{diaryAxTask.number_of_days_left}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </>
                  ) : (
                    <Col>
                      <p>
                        There is no diary assessmnet assigned to this
                        participant.
                      </p>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </>
        )}
      </ModalContainer>
    </>
  )
}

export default DiaryAxTask
