import React, { useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'

export const DownloadTextResponseModalContainer = ({
  title,
  children,
  show,
  showClose,
  handleDownload,
  handleClose,
  warningText,
  isLoading,
}) => {
  useEffect(() => {}, [children])

  return (
    <Modal show={show} size='lg' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: '100%', height: '400px', overflow: 'auto' }}>
          {children}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {showClose && (
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        )}

        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <Button variant='primary' onClick={handleDownload}>
            Download Text Responses {warningText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default DownloadTextResponseModalContainer
