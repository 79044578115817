export const QX_LIST_REQUEST = 'QX_LIST_REQUEST'
export const QX_LIST_SUCCESS = 'QX_LIST_SUCCESS'
export const QX_LIST_FAIL = 'QX_LIST_FAIL'
export const QX_LIST_RESET = 'QX_LIST_RESET'

export const QX_CREATE_REQUEST = 'QX_CREATE_REQUEST'
export const QX_CREATE_SUCCESS = 'QX_CREATE_SUCCESS'
export const QX_CREATE_FAIL = 'QX_CREATE_FAIL'
export const QX_CREATE_RESET = 'QX_CREATE_RESET'

export const QX_DELETE_REQUEST = 'QX_DELETE_REQUEST'
export const QX_DELETE_SUCCESS = 'QX_DELETE_SUCCESS'
export const QX_DELETE_FAIL = 'QX_DELETE_FAIL'
export const QX_DELETE_RESET = 'QX_DELETE_RESET'

export const QX_DETAILS_REQUEST = 'QX_DETAILS_REQUEST'
export const QX_DETAILS_SUCCESS = 'QX_DETAILS_SUCCESS'
export const QX_DETAILS_FAIL = 'QX_DETAILS_FAIL'
export const QX_DETAILS_RESET = 'QX_DETAILS_RESET'

export const QX_EDIT_REQUEST = 'QX_EDIT_REQUEST'
export const QX_EDIT_SUCCESS = 'QX_EDIT_SUCCESS'
export const QX_EDIT_FAIL = 'QX_EDIT_FAIL'
export const QX_EDIT_RESET = 'QX_EDIT_RESET'

export const MC_LIST_REQUEST = 'MC_LIST_REQUEST'
export const MC_LIST_SUCCESS = 'MC_LIST_SUCCESS'
export const MC_LIST_FAIL = 'MC_LIST_FAIL'
export const MC_LIST_RESET = 'MC_LIST_RESET'

export const MC_CREATE_REQUEST = 'MC_CREATE_REQUEST'
export const MC_CREATE_SUCCESS = 'MC_CREATE_SUCCESS'
export const MC_CREATE_FAIL = 'MC_CREATE_FAIL'
export const MC_CREATE_RESET = 'MC_CREATE_RESET'

export const MC_EDIT_REQUEST = 'MC_EDIT_REQUEST'
export const MC_EDIT_SUCCESS = 'MC_EDIT_SUCCESS'
export const MC_EDIT_FAIL = 'MC_EDIT_FAIL'
export const MC_EDIT_RESET = 'MC_EDIT_RESET'

export const MC_DETAILS_REQUEST = 'MC_DETAILS_REQUEST'
export const MC_DETAILS_SUCCESS = 'MC_DETAILS_SUCCESS'
export const MC_DETAILS_FAIL = 'MC_DETAILS_FAIL'
export const MC_DETAILS_RESET = 'MC_DETAILS_RESET'

export const MC_DELETE_REQUEST = 'MC_DELETE_REQUEST'
export const MC_DELETE_SUCCESS = 'MC_DELETE_SUCCESS'
export const MC_DELETE_FAIL = 'MC_DELETE_FAIL'
export const MC_DELETE_RESET = 'MC_DELETE_RESET'
